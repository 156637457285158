import axios from 'axios';
import config from '@/config';
import store from '@/store';

export default () => {
  const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
  const useLoginToken = store.getters['app/getValue']('isContinuation')
    || store.getters['app/getValue']('isRenewal')
    || store.getters['app/getValue']('isSavedProposal')
    || store.getters['app/getValue']('isBroker');
  const token = useLoginToken ? sessionStorage.getItem('login-token-' + config.environment + '-' + ts)
    : localStorage.getItem('guest-token-' + config.environment + '-' + ts);

  const instance = axios.create({
      timeout: config.api.timeout,
      headers: {
          common: {
          Authorization: token
          }
      },
      withCredentials: true
  });

  return instance;
};
