import { Colors, Theme } from '@/models';

const themes: any = {
	default: new Theme(
		'default',
		new Colors()
	),
};

export { themes };
