import Utils from '@/views/auth/Portal/PortalStepperItems/Utils';
import TechUtils from './Util';
import _ from 'lodash';

export const USE_PRICING = 'PRICING';
export const USE_UNDERWRITING = 'UNDERWRITING';
export const USE_POLICY = 'POLICY';
export const USE_DETAIL = ['ASSET_DETAIL', 'APPLICANT_DETAIL'];
export const BUSINESS_SECTION_NAME = 'policyholder';
export default class ProductEngineUtils {

  public static getInitialProductDescription(productSession: any) {
    const calculation = this.calculate(productSession);
    const description = this.redescribeProduct(productSession);
    return {
      description,
      calculation
    };
  }

  public static serializeSession(productSession: any) {
    return productSession.serializeConfig();
  }


  public static getProductTree(productDescription: any): any {
    const product = TechUtils.convertToEsCompatible(productDescription.description.product);
    const facts = TechUtils.convertToEsCompatible(productDescription.description.facts);
    const policy = TechUtils.convertToEsCompatible(productDescription.description.policy);

    const productTree: any = {
      allFacts: facts.facts,
      code: product.code,
      pricingFacts: Utils.getFactsByUse(facts.facts, USE_PRICING),
      underwritingFacts: Utils.getFactsByUse(facts.facts, USE_UNDERWRITING),
      policyFacts: Utils.getFactsByUse(facts.facts, USE_POLICY),
      benefits: policy.benefits,
      components: []
    };
    if (Utils.getFactsByUse(facts.facts, USE_DETAIL).length) {
      productTree.detailFacts = Utils.getFactsByUse(facts.facts, USE_DETAIL);
    }
    _.forEach(product.components, (c: any, index: number) => {
      const componentFacts = facts.components[index].facts;
      const comp: any = {
        code: c.componentCode,
        name: c.componentName,
        insuredTypes: this.getInsuredTypes(c.insuredTypes, product),
        allFacts: componentFacts,
        pricingFacts: Utils.getFactsByUse(componentFacts, USE_PRICING),
        underwritingFacts: Utils.getFactsByUse(componentFacts, USE_UNDERWRITING),
        policyFacts: Utils.getFactsByUse(componentFacts, USE_POLICY),
        benefits: policy.components[index].benefits,
        assets: []
      };
      if (Utils.getFactsByUse(componentFacts, USE_DETAIL).length) {
        comp.detailFacts = Utils.getFactsByUse(componentFacts, USE_DETAIL);
      }
      _.forEach(policy.components[index].insured, (i: any, insuredIdx) => {
        if (_.some(c.insuredTypes, (type: any) => type === i.type)) {
          const insured = facts.components[index].insured[insuredIdx];
          const insuredFacts = _.get(insured, 'facts', []);
          const asset: any = {
            id: i.id,
            name: i.name,
            type: i.type,
            allFacts: insuredFacts,
            pricingFacts: Utils.getFactsByUse(insuredFacts, USE_PRICING),
            underwritingFacts: Utils.getFactsByUse(insuredFacts, USE_UNDERWRITING),
            policyFacts: Utils.getFactsByUse(insuredFacts, USE_POLICY),
            benefits: _.get(i, 'benefits', [])
          };
          if (Utils.getFactsByUse(insuredFacts, USE_DETAIL).length) {
            asset.detailFacts = Utils.getFactsByUse(insuredFacts, USE_DETAIL);
          }
          comp.assets.push(asset);
        }
      });
      productTree.components.push(comp);
    });
    return productTree;
  }

  public static getPolicyCoverageStartPeriod(productSession: any) {
    return _.get(this.redescribeProduct(productSession), 'product.policyPeriod', false);
  }

  public static getFactValue(productSession: any, factId: string) {
    return productSession.getFactValue(factId);
  }

  public static getPrice(session: any) {
    return this.calculate(session);
  }

  public static getProductLanguages(productSession: any) {
    return _.get(this.redescribeProduct(productSession), 'product.languages', '');
  }

  public static setLanguage(productSession: any, language: any) {
    return productSession.setLanguage(language);
  }

  private static redescribeProduct(productSession: any) {
    if (!productSession) {
      return {
        product: {},
        facts: {},
        policy: {}
      };
    }

    return {
      product: TechUtils.convertToEsCompatible(productSession.describeProduct()),
      facts: TechUtils.convertToEsCompatible(productSession.describeFacts()),
      policy: TechUtils.convertToEsCompatible(productSession.describePolicy())
    };
  }

  private static calculate(productSession: any) {
    if (!productSession) {
      return {};
    }

    const calculation: any = {
      validation: null,
      price: null,
      underwriting: null
    };

    try {
      calculation.validation = TechUtils.convertToEsCompatible(productSession.validate());
    } catch (e) {
      console.error('calculation: validation failed', e);
    }

    try {
      calculation.price = TechUtils.convertToEsCompatible(productSession.calculatePrice());
    } catch (e) {
      console.error('calculation: pricing failed', e);
    }

    try {
      calculation.underwriting = TechUtils.convertToEsCompatible(productSession.underwrite());
    } catch (e) {
      console.error('calculation: underwriting failed', e);
    }

    return calculation;
  }

  private static getInsuredTypes(types: string[], productDescription: any) {
    const result: any[] = [];
    _.forEach(types, (type: string) => {
      const productInsured = _.find(productDescription.insured, (i: any) => i.id === type);
      const insuredType = {
        type,
        minAllowed: productInsured.minAllowed,
        maxAllowed: productInsured.maxAllowed,
        description: productInsured.description
      };
      result.push(insuredType);
    });

    return result;
  }
}
