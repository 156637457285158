import store from '@/store';
import _ from 'lodash';

export class CarjamService {
  public static async searchByPlate(plate: string) {
    const config = _.get(store, 'state.app.config.carjam', {});
    if (config.src && config.key) {
      const query = '?f=json&translate=1&plate=' + encodeURIComponent(plate) + '&key=' + config.key;
      const response = await fetch(config.src + query, {}).catch((e) => {
        console.error(e);
      });
      if (response && response.ok) {
        const text = await response.text();
        return text;
      }
    }
  }
}
