import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import config from '@/config';

import store from '@/store';
import HttpInterceptors from '@/utils/interceptors/HttpInterceptors';
import EventBus from '@/common/EventBus';
import jwtDecode from 'jwt-decode';

class ApiResource {
  private static instance: ApiResource;
  public axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: window.location.origin + config.api.publicPath,
      timeout: config.api.timeout,
      withCredentials: true
    });

    this.axiosInstance.interceptors.request.use(HttpInterceptors.requestInterceptor);
    this.axiosInstance.interceptors.response.use(HttpInterceptors.responseInterceptor, errorInterceptor);
  }

  public static get Instance() {
    return this.instance || (this.instance = new this());
  }

  public get(url: string, cfg = {}, protection = true) {
    return this.request('get', url, cfg, {}, protection);
  }

  public post(url: string, params: any, cfg = {}) {
    return this.request('post', url, params, cfg);
  }

  public put(url: string, params: any, cfg = {}) {
    return this.request('put', url, params, cfg);
  }

  public patch(url: string, params: any, cfg = {}) {
    return this.request('patch', url, params, cfg);
  }

  public delete(url: string, params: any, cfg = {}) {
    return this.request('delete', url, params, cfg);
  }
  public setUser(isGuest = false) {
    const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
    const shiftToken = (store.state.app.isContinuation || store.state.app.isRenewal || store.state.app.isBroker) ?
      sessionStorage.getItem('login-token-' + config.environment + '-' + ts) :
      localStorage.getItem('guest-token-' + config.environment + '-' + ts);
    if (shiftToken) {
      try {
        const token: any = jwtDecode(shiftToken);
        if (token) {
          store.dispatch('auth/setAuth', { accountId: token.aid, userId: token.uid, isGuest });
          Sentry.configureScope((scope) => {
            scope.setTag('shift.tokenId', token.jti);
            scope.setUser({
              id: token.uid
            });
          });
        }
      } catch (error) {
        console.error('Failed to parse shift token', error);
      }
    }
  }

  private callMethod(instance: any, name: string) {
    return instance[name];
  }

  private async request(
    method: string,
    url: string,
    params: any,
    cfg = {},
    protection = true
  ) {
    let canProceed = true;
    if (protection) {
      canProceed = !!await this.injectAuthToken(method === 'get' ? params : cfg);
    }
    let response = canProceed && await this.callMethod(this.axiosInstance, method)(url, params, cfg);
    if (!response) {
      EventBus.$on('got-token', async () => {
        this.setUser();
        if (protection) {
          canProceed = !!await this.injectAuthToken(method === 'get' ? params : cfg);
        }
        response = canProceed && await this.callMethod(this.axiosInstance, method)(url, params, cfg).catch((e) => {
          console.error(e);
        });
      });
      function sleep(ms = 0) {
        return new Promise((r) => setTimeout(r, ms));
      }
      while (!response) {
        await sleep(1000);
      }
    }
    return response;
  }

  private injectAuthToken(cfg: any = {}) {
    return new Promise((resolve) => {
      cfg.headers = cfg.headers || {};
      const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      const useLoginToken = store.getters['app/getValue']('isContinuation')
        || store.getters['app/getValue']('isRenewal')
        || store.getters['app/getValue']('isSavedProposal')
        || store.getters['app/getValue']('isBroker');
      const token = useLoginToken ? sessionStorage.getItem('login-token-' + config.environment + '-' + ts)
        : localStorage.getItem('guest-token-' + config.environment + '-' + ts);

      if (token) {
        cfg.headers.Authorization = 'Bearer ' + token;
        resolve(true);
      } else {
        EventBus.$emit('need-token', () => {
          EventBus.$emit('got-token');
        });
        resolve(false);
      }
    });
  }
}

function errorInterceptor(err) {
  if (err.response) {
    if (err.response.status === 404) {
      EventBus.$emit('error-404');
      return Promise.reject(err);
    } else if (err.response.status === 401 || err.response.status === '401') {
      const ts = sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment;
      localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
      sessionStorage.removeItem('login-token-' + config.environment + '-' + ts);
      EventBus.$emit('api-done', {
        method: _.get(err, 'config.method')
      });
      EventBus.$emit('need-token', () => {
        EventBus.$emit('got-token');
      });
      return Promise.resolve('401');
    } else if (err.response.status === 402 || err.response.status === '402') {
      const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
      sessionStorage.removeItem('login-token-' + config.environment + '-' + ts);
      EventBus.$emit('api-done', {
        method: _.get(err, 'config.method')
      });
      EventBus.$emit('need-token', () => {
        EventBus.$emit('got-token');
      });
      return Promise.resolve('402');
    } else if (err.response.status === 412 || err.response.status === '412') {
      return Promise.resolve('412');
    }
  }
  EventBus.$emit('api-error', {
    method: _.get(err, 'config.method'),
    url: _.get(err, 'config.url', ''),
    data: _.get(err, 'response.data')
  });
}

export const APIResource = ApiResource.Instance;
