import {IApp} from '@/interfaces';
import locales from '@/locales';
import _ from 'lodash';

const defaultLocale = _.find(locales, {default: 'true'});

export default (): IApp => {
  return {
    activeStep: 0,
    totalSteps: 0,
    furthestStep: 0,
    language: (defaultLocale as any).locale,
    languages: [],
    journeyLanguages: [],
    targetSegment: null,
    profile: null,
    line: null,
    areaOfService: null,
    products: [],
    paymentProviders: [],
    homeUrl: null,
    catalogueUrl: null,
    showRenewLink: null,
    showSaveProposalButton: null,
    showSaveProposalButtonOnValidPrice: false,
    showDatesSection: null,
    renewalSkipCatalogue: null,
    showRequestButton: null,
    adviserPhone: null,
    adviserEmail: null,
    isFooterVisible: false,
    helpAddress: null,
    helpPhone: null,
    helpFax: null,
    helpEmail: null,
    downloadAddress: null,
    downloadFax: null,
    policyIds: [],
    bundleId: '',
    error: '',
    detectedCountry: '',
    targetShiftClientId: null,
    config: {},
    dirty: false,
    loadingText: null,
    isContinuation: false,
    isRenewal: false,
    isSavedProposal: false,
    isBroker: false,
    pardotAvailability: false,
    renewalResponse: {},
    savedProposal: null,
    originUrl: '',
    proposalId: null,
    keyboardUp: false,
    lastCMSUpdate: null,
    copyCoverageDate: false,
    isPayButtonVisible: true,
    affiliateCode: '',
    helpAddresses: [],
    isContentReady: false,
    isPeInProgress: false,
    contactInfoFacts: {
      'applicant.firstname': 'givenName',
      'applicant.lastname': 'familyName',
      'applicant.email': 'email',
      'applicant.phone': 'phone'
    }
  };
};
