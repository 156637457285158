import {APIResource, APIError} from '@/utils/APIResources';
import {AxiosError} from 'axios';
import {} from 'shift-quote-service-api-client';

export default class {

  public static createQuote(payload) {
    return APIResource.post('/v2/quote/proposal', payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static updateQuote(proposalId, payload) {
    return APIResource.put('/v2/quote/proposal/' + proposalId, payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static listQuote() {
    return APIResource.get('/v2/quote/quote')
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static getQuotes(proposalId: string) {
    return APIResource.get('/v2/quote/proposal/' + proposalId)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }
}
