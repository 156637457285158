import _ from 'lodash';
import stringHash from 'string-hash';
import EventBus from '@/common/EventBus';
import {IMap} from '@/interfaces/IMap';
import Proposal from '@/models/proposal';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import store from '@/store';
import removeAccents from 'remove-accents';

const LOCATION_FACT_ID = 'applicant.province';
const RENDER_NEXT_TO_PARENT = 'RENDER_NEXT_TO_PARENT';
export const FactTag = {
  NO_UI: 'no-ui',
  INSURED_INFO: 'insured-info',
  NO_DISTRIBUTION: 'no-distribution',
};

export default {

  getLocation(proposal: Proposal) {
    const location = proposal.productLevelFacts.find((fact) => fact.id === LOCATION_FACT_ID);
    return location ? location!.value : '';
  },

  getTax(proposal: any) {
    const taxList = {};
    let totalTax = 0;
    const allLocations: any[] = [];
    const allPercentages: any[] = [];
    _.forEach(proposal.products, (product) => {
      if (_.get(product, 'price') && !product.price.taxInclusive) {
        const {price} = product;
        const location = this.getLocation(proposal);
        taxList[product.id] = {
          amount: price.taxAmount,
          inclusive: price.taxInclusive,
          percentage: price.taxPercent,
          location
        };
        if (allLocations.indexOf(location) === -1) {
          allLocations.push(location);
          allPercentages.push(price.taxPercent + '%');
        }
        if (!price.taxInclusive) {
          totalTax += price.taxAmount;
        }
      }
    });
    if (!_.isEmpty(taxList)) {
      return {
        ...taxList,
        totalTax,
        allLocations,
        allPercentages
      };
    } else {
      return false;
    }
  },

  getFactsByType(obj: any, type: string|string[]) {
    const res = _.filter(_.get(obj, 'facts', []), (f: any) => {
      if (typeof type === 'string') {
        type = [type];
      }
      for (const t of type) {
        if (_.get(f, 'type.type') === t) {
          return true;
        }
      }
      return false;
    });
    return res;
  },

  getFactsByLevel(obj: any, level: string) {
    const res = _.filter(_.get(obj, 'facts', []), (f: any) => {
      return _.get(f, 'level.type') === level;
    });
    return res;
  },

  // used to filter
  getFactsByUse(facts: any, useType: string|string[], ignoreType: string|string[] = [], omittedFacts: string[] = []) {
    const res = _.filter(facts || [], (f: any) => {
      if (typeof useType === 'string') {
        useType = [useType];
      }
      if (typeof ignoreType === 'string') {
        ignoreType = [ignoreType];
      }
      // const types = _.map(_.get(f, 'usedBy'), 'type');
      const types = f.usedBy;
      const originalId = _.get(f, 'id', '').replace(/^PRODUCT:.*#/, '');

      return _.find(types, (t) => _.includes(useType, t)) && !_.find(types, (t) => _.includes(ignoreType, t)) && !_.includes(omittedFacts, originalId);
    });
    return res;
  },

  productUniqueLevelFacts(productDescriptions: any[]) {
    const facts: any[] = [];
    _.forEach(productDescriptions, (desc: any) => {
      facts.push(desc.facts);
    });
    return _.uniqBy(_.flatten(facts), 'id');
  },

  getProductLevelFact(filteredProducts: any[], factId: string) {
    const facts = this.productUniqueLevelFacts(filteredProducts);
    return _.find(facts, (f: any) => {
      return f.id === factId;
    });
  },

  uniqueByIdWithValue(facts: any[]) {
    const result: any[] = [];

    _.forEach(facts, (fact: any) => {
      const existingFact = _.find(result, {id: fact.id});

      if (!existingFact) {
        result.push(fact);
      } else if (existingFact.currentValue === '' && fact.currentValue) {
        existingFact.currentValue = fact.currentValue;
      }
    });

    return _.filter(result, 'currentValue');
  },

  shouldHideFact(fact: any) {
    return fact.tags.includes(FactTag.NO_UI) || fact.tags.includes(FactTag.NO_DISTRIBUTION);
  },

  customModeler(facts: any[], models: any) {
    const finalFacts = [...facts];
    _.forEach(models, (ids, key) => {
      _.forEach(ids, (id) => {
        const snippedFacts = _.remove(finalFacts, (fact: any) => !!fact.id.endsWith(id) || fact.childOf.endsWith(id));
        if (snippedFacts && snippedFacts.length > 0) {
          const modelFact = _.cloneDeep(_.find(snippedFacts, (fact: any) => fact.type === 'GROUP') || snippedFacts[0]);
          modelFact.type = key;
          modelFact.mandatory = modelFact.mandatory || !!_.find(snippedFacts, (fact: any) => fact.mandatory);
          switch (key) {
            case 'CUSTOM_MULTI_SELECT':
              modelFact.options = [];
              _.forEach(snippedFacts, (snippedFact: any) => {
                if (snippedFact.type === 'BOOLEAN') {
                  modelFact.options.push({
                    name: snippedFact.name,
                    value: snippedFact.id,
                    currentValue: snippedFact.currentValue === 'true'
                  });
                }
              });
              break;
          }
          finalFacts.push(modelFact);
        }
      });
    });
    return finalFacts;
  },

  getGenericFactsConfig(step: string = '') {
    const genericFactConfig = _.get(store.state.app.config.shiftMappings, 'GENERIC_FACTS', null);

    if (!genericFactConfig) { return null; }

    if (step) {
      return _.get(genericFactConfig, step, null);
    } else {
      const keys = Object.keys(genericFactConfig) || [];
      let facts: any[] = [];
      keys.forEach((key: string) => {
        facts = [...facts, ...genericFactConfig[key].facts];
      });
      return facts;
    }
  },

  getGenericFacts(facts: any[], genericFactIds?: string[] ) {
    const configureGenericFacts: string[] = genericFactIds || this.getGenericFactsConfig();
    const factIds = facts.map((f: any) => f.id);
    return _.uniq(_.intersectionWith(configureGenericFacts, factIds, _.isEqual));
  },

  getFactGroups(facts: any[], factGroups: IMap, manualGroups: any, productCode: string = '', groupRoots: any = [], ignoreBaseRoot: string|string[] = [], ignoreGenericFacts: boolean = true) {
    // console.trace({facts});
    const genericFacts = this.getGenericFacts(facts);

    const groups: any[] = [];
    _.forEach(facts, (fact: any) => {

      if (!ignoreGenericFacts && genericFacts.includes(fact.id)) { return; }

      if (this.shouldHideFact(fact)) {
        return;
      }

      if (fact.type === 'GROUP' && (ignoreBaseRoot === '*' || _.includes(ignoreBaseRoot, fact.id)) && fact.childOf === '') {
        const fgName = factGroups[`PRODUCT:${productCode}#${fact.id}`];
        if (fgName) {
          groups.push({
            name: fgName,
            header: true,
            facts: []
          });
        }
        return;
      }
      const factId = fact.id.replace(/:\d+/g, ':x');
      const exception = this.nextToParent(fact);
      if (exception) {
        const child = fact;
        const parentExists = _.findLast(groups, (p) => p.name === child.childOf);
        if (parentExists) {
          parentExists.facts.push(child);
        } else {
          const parentInGroup = _.findLast(groups, (g) => g.facts.some((f) => f.id === child.childOf));
          if (parentInGroup) {
            const beforeGroup = {...parentInGroup};
            const afterGroup = {...parentInGroup};
            afterGroup.header = false;
            const beforeIndex = _.findLastIndex(groups, (g) => g.name === parentInGroup.name);
            let searchingCount = 0;
            const index = _.findLastIndex(parentInGroup.facts, (f: any) => {
              if (f.id === child.childOf || f.childOf === child.childOf) {
                searchingCount++;
                return true;
              } else {
                return false;
              }
            });
            if (index - searchingCount > 0) {
              beforeGroup.facts = parentInGroup.facts.splice(0, index - (searchingCount - 1));
              groups.splice(beforeIndex, 0, beforeGroup);
            }
            if (index < parentInGroup.length - 1) {
              afterGroup.facts = parentInGroup.facts.splice(index + 1);
            } else {
              afterGroup.facts = [];
            }
            if (beforeIndex === groups.length - 1) {
              groups.push(afterGroup);
            } else {
              groups.splice(beforeIndex + 1, 0, afterGroup);
            }
            parentInGroup.facts.push(child);
            parentInGroup.header = false;
          } else {
            const item = { name: child.childOf, facts: [child]};
            groups.push(item);
          }
        }
        return;
      }
      if (groupRoots.includes(factId)) {
        if (!groups.find((g) => g.name === factId)) {
          groups.push({
            name: factGroups[`PRODUCT:${productCode}#${factId}`] || factGroups[factId] || factId,
            header: true,
            root: fact,
            facts: []
          });
        }
        return;
      }

      const foundRoot = _.findLast(groupRoots, (root) => root === fact.childOf || factId.includes(root + '.'));
      if (foundRoot) {
        const root = _.findLast(groups, (g: any) => {
          return g.name === factGroups[`PRODUCT:${productCode}#${foundRoot}`] || g.name === fact.childOf || g.name === foundRoot;
        });
        if (!_.isEmpty(root)) {
          root.facts.push(fact);
        }
        return;
      }
      const name = factGroups[`PRODUCT:${productCode}#${factId}`] || factGroups[factId] ||
        factGroups[`PRODUCT:${productCode}#${fact.childOf}`] || factGroups[fact.childOf] ||
        (fact.type !== 'GROUP' && _.last(groups) && _.last(groups).name === fact.childOf && _.last(groups).name) || null;
      if (name) {
        const group = _.findLast(groups, (g: any) => {
          return g.name === name;
        });
        if (!_.isEmpty(group)) {
          group.facts.push(fact);
        } else {
          groups.push({
            name,
            header: true,
            facts: [fact],
            mandatory: !![fact].find((f: any) => f.mandatory)
          });
        }
      } else {
        if (fact.type === 'GROUP') {
          groups.push({
            name: factGroups[`PRODUCT:${productCode}#${factId}`] || factGroups[factId] || fact.id,
            header: true,
            root: fact,
            facts: []
          });
          return;
        }
        if (fact.childOf) {
          const pGroup = _.findLast(groups, (g) => g.facts.some((f) => f.id === fact.childOf));
          let index = -1;
          if (pGroup) {
            _.forEach(pGroup.facts, (f, idx) => {
              if (f.id === fact.childOf || f.childOf === fact.childOf) {
                index = parseInt(idx, 10);
              } else if (index !== -1) {
                return false;
              }
            });
          }
          if (index !== -1) {
            pGroup.facts.splice(index + 1, 0, fact);
          } else {
            groups.push({
              name: fact.childOf,
              facts: [fact]
            });
          }
        } else {
          groups.push({
            name: factId,
            facts: [fact]
          });
        }
      }
    });
    _.forEach(groups, (group: any) => {
      if (_.includes(_.keys(manualGroups), group.name)) {
        const keys = _.keys(_.pickBy(factGroups, (fg: any) => fg === group.name));
        group.facts = [];
        const manual = manualGroups[group.name];
        _.forEach(keys, (key: any) => {
          group.facts[manual[key]] = _.find(facts, (f: any) => f.id.replace(/:\d+/g, ':x') === key);
        });
        group.facts = _.compact(group.facts);
      }
      group.mandatory = !!_.find(group.facts, (f) => f.mandatory);
    });

    let retGroups = _.cloneDeep(groups);
    if (manualGroups.base) {
      _.forEach(groups, (group: any) => {
        const key: string = `${_.find(_.keys(manualGroups.base), (k: string) => group.name.endsWith(k))}`;
        if (key) {
          const pos = manualGroups.base[key];
          const currIndex = _.findIndex(retGroups, (g) => g.name === group.name);
          if (_.isNumber(pos) && pos > 0) {
            let index = 0;
            const found = _.find(_.keys(manualGroups.base), (k: any) => {
              index = _.findIndex(retGroups, (g) => g.name === k);
              return index >= 0 && manualGroups.base[k] === pos - 1;
            });
            if (found && index > currIndex) {
              const temp = _.remove(retGroups, (g) => g.name === group.name);
              if (index === retGroups.length) {
                retGroups = [...retGroups, ...temp];
              } else {
                retGroups = [
                  ...retGroups.slice(0, index - 1),
                  ...temp,
                  ...retGroups.slice(index - 1)
                ];
              }
            }
          } else if (pos) {
            const action = pos.split(':');
            if (action.length === 2) {
              const temp = _.remove(retGroups, (g) => g.name === group.name);
              const target = action[1];
              const targetIndex = _.findIndex(retGroups, (g) => g.name.endsWith(target));
              if (targetIndex >= 0) {
                if (targetIndex === 0 && action[0] === 'before') {
                  retGroups = [...temp, ...retGroups];
                } else if (targetIndex === retGroups.length && action[0] === 'after') {
                  retGroups = [...retGroups, ...temp];
                } else {
                  const toPut = targetIndex + (action[0] === 'after' ? 1 : 0);
                  if (toPut > 0 && toPut < retGroups.length) {
                    retGroups = [
                      ...retGroups.slice(0, toPut),
                      ...temp,
                      ...retGroups.slice(toPut)
                    ];
                  }
                }
              }
            }
          }
        }
      });
    }
    return retGroups;
  },

  nextToParent(fact: any) {
    const list = _.get(store.state.app.config.shiftMappings, RENDER_NEXT_TO_PARENT, []);
    return list.some((key) => fact.id.endsWith(key) || (fact.id.startsWith(key) && fact.childOf.startsWith(key)));
  },

  getUnderwritingResult(products: any) {
    const result = _.map(products, (product) => {
      const status = ProductEngineUtils.getPrice(product.session);
      const underwritingResult = _.get(status, 'underwriting.status.type');
      return {
        id: product.id,
        productDescriptionId: product.productDescriptionId,
        underwritingResult
      };
    });
    return result;
  },

  isUwStatusOk(status, isContinuation = false) {
    const underwritingResult = _.get(status, 'underwriting.status.type');
    if (isContinuation && _.includes(['OK', 'MANUAL'], underwritingResult)) {
      return true;
    }
    if (underwritingResult !== 'OK') {
      return false;
    }
    return _.get(status, 'price.valid') &&
      !_.get(status, 'price.needsManualPricing', true);
  },

  isPriceValid(session: any, isContinuation = false) {
    const status = ProductEngineUtils.getPrice(session);
    const uwStatus = _.get(status, 'underwriting.status.type', 'OK');
    if (isContinuation && _.includes(['OK', 'MANUAL'], uwStatus)) {
      return true;
    }
    if (uwStatus !== 'OK') {
      return false;
    }
    return _.get(status, 'price.valid') &&
      !_.get(status, 'price.needsManualPricing', true);
  },

  getGroupText(ref: any, group: any, step: any, productCode: any = null, shiftMappings: any = null) {
    const omitted = _.get(shiftMappings, 'OMITTED_GROUP_LABELS', {});
    if (productCode && group.name && (omitted[productCode] || []).includes(group.name)) {
      return '';
    }
    const named = _.get(shiftMappings, 'NAMED_FACT_GROUPS', []);
    const factsCount = _.get(group, 'facts.length', 0);
    const singleFactOnly = factsCount === 1 && group.facts[0];
    const ret = ref.$t(`proposal.${step}.groups.${productCode}['${group.name}']`) ||
          ref.$t(`proposal.${step}.groups.${group.name}`) ||
          ref.$t(`proposal.${step}.groups.${named[group.name]}`) ||
          (group.root && _.get(group, 'root.name')) ||
          (singleFactOnly && singleFactOnly.name ? '' : (_.get(group, 'root.name') || (group.name && named[group.name]) || ''));
    return ret;
  },

  getAddressFields(factId: string, address: any, customMappings: any = {}) {
    let prefix: any = factId.split('.');
    prefix.pop();
    prefix = prefix.join('.');
    const format = (data) => _.map(_.filter(_.values(data), (val: any) => typeof val === 'string'), (val: any) => removeAccents(val));
    const dataFields = [
      {postfix: '.postalcode', val: 'postalCode'},
      {postfix: '.postcode', val: 'postalCode'},
      {postfix: '.city', val: 'city'},
      {postfix: '.province', val: 'state'},
      {postfix: '.state', val: 'state'}
    ];
    let fields: any[] = [];
    for (const dataField of dataFields) {
      const val = _.get(address, dataField.val);
      if (val) {
        fields.push({
          id: prefix + dataField.postfix,
          val: format(val)
        });
      }
    }
    const links = customMappings[prefix];
    const keys = _.keys(address);
    _.forEach(keys, (key) => {
      if (links && links[key]) {
        const facts = links[key];
        const val = format(_.get(address, `${key}`));
        if (val) {
          fields = [...fields, ..._.map(facts, (fact) => ({id: fact, val}))];
        }
      }
    });
    return fields;
  },

  registerClass(shiftMappings: any, fact: any, productCode: any, addFirst?: boolean) {
    const whitelist = _.get(shiftMappings, 'SYNONYMS', []);
    for (const arr in whitelist) {
      if (whitelist[arr].includes(fact.id)) {
        const hash = 'id-' + stringHash(arr);
        EventBus.$emit('FactAdded', hash, productCode, fact.id);
        return hash + (addFirst ? '-first' : '');
      }
    }
    return '';
  }
};
