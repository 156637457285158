import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { isSentryEnabled } from '@/config';
import _ForEach from 'lodash/forEach';

export default class SentryService {

  public static logMessage(payload: SentryMessage) {
    if (!isSentryEnabled()) {
      return;
    }
    Sentry.withScope((scope) => {
      this.setCommonScopeData(payload, scope);
      Sentry.captureMessage(payload.message);
    });
  }

  public static logException(payload: SentryException) {
    if (!isSentryEnabled()) {
      return;
    }
    Sentry.withScope((scope) => {
      this.setCommonScopeData(payload, scope);
      scope.setTag('api.error', payload.type);
      if (typeof payload.error !== 'string') {
        Sentry.captureException(payload.error);
      } else {
        Sentry.captureMessage(payload.error, Severity.Error);
      }
    });
  }

  private static setCommonScopeData(payload: any, scope: any) {
    if (payload.level) {
      scope.setLevel(Severity[payload.level.toLowerCase()]);
    }
    scope.setTag('log.datetime', JSON.stringify(new Date()));
    if (payload.tags) {
      _ForEach(payload.tags, (value, key) => {
        scope.setTag(key, value);
      });
    }
    if (payload.data) {
      scope.setExtra('data', JSON.stringify(payload.data));
    }
  }
}

export interface SentryMessage {
  message: string;
  data?: any;
  tags?: any;
  level?: string;
}

export interface SentryException {
  type: 'PARDOT' | 'SHIFT' | 'LIVECHAT' | 'GOOGLE_MAPS' | 'APP';
  error: any;
  tags?: any;
  data?: any;
  level?: string;
}
