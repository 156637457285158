import {ActionTree, ActionContext} from 'vuex';

import {IRawProducts, IRoot} from '@/interfaces';
import ProductService from '@/services/ProductService';
import _ from 'lodash';
import {LOAD, RESET, ADD} from './mutationTypes';
import router from '@/router';

const actions: ActionTree<IRawProducts, IRoot> = {
  async list(
    {commit, rootState}: ActionContext<IRawProducts, IRoot>
  ): Promise<any> {
    return await ProductService.list()
    .then((resp: any) => {
      if (resp !== '401') {
        const products: any[] = _.get(resp, 'data.data', []);
        commit(LOAD, {
          products,
          order: rootState.app.config.shiftMappings.PRODUCT_PRIORITY_ORDER
        });
        return Promise.all(products);
      }
    })
    .catch((err: any) => {
      router.push({ path: '/error' });
      return Promise.reject(err);
    });
  },

  async loadProduct(
    {commit, rootState}: ActionContext<IRawProducts, IRoot>,
    id: string
  ): Promise<any> {
    return await ProductService.getProduct(id)
    .then((resp: any) => {
      if (resp !== '401') {
        const product = _.get(resp, 'data.data');
        if (product) {
          commit(ADD, {
            product,
            order: rootState.app.config.shiftMappings.PRODUCT_PRIORITY_ORDER
          });
          return Promise.resolve(product);
        } else {
          return Promise.resolve(null);
        }
      }
    })
    .catch((err: any) => {
      return Promise.reject(err);
    });
  },

  reset({commit}: ActionContext<IRawProducts, IRoot>): void {
    commit(RESET);
  },
};

export default actions;
