export const RESET: string = 'RESET';
export const SET_PRODUCTS: string = 'SET_PRODUCTS';
export const UPDATE_CONTACT_INFO: string = 'UPDATE_CONTACT_INFO';
export const SET_BASIC_INFO: string = 'SET_BASIC_INFO';
export const SET_COVERAGE_DATE: string = 'SET_COVERAGE_DATE';
export const SET_QUOTE_NUMBER: string = 'SET_QUOTE_NUMBER';
export const SET_PRODUCT_SESSION: string = 'SET_PRODUCT_SESSION';
export const SET_PRODUCT_POLICY_NUMBER: string = 'SET_PRODUCT_POLICY_NUMBER';
export const SET_PRODUCT_POLICY_ID: string = 'SET_PRODUCT_POLICY_ID';
export const SET_PROPOSAL_NUMBER: string = 'SET_PROPOSAL_NUMBER';
export const SET_PAYMENT_TOKEN: string = 'SET_PAYMENT_TOKEN';
export const ADD_PRODUCT: string = 'ADD_PRODUCT';
export const DELETE_PRODUCT: string = 'DELETE_PRODUCT';
export const SET_PAYMENT_SKIPPED: string = 'SET_PAYMENT_SKIPPED';
export const SET_NO_PAYMENT: string = 'SET_NO_PAYMENT';
export const SET_PROPOSAL_INVOICE_ID: string = 'SET_PROPOSAL_INVOICE_ID';
export const SET_INVOICES: string = 'SET_INVOICES';
export const SET_REFUND: string = 'SET_REFUND';
export const SET_IS_ENDORSMENT: string = 'SET_IS_ENDORSMENT';
export const UPDATE_BASIC_INFO: string = 'UPDATE_BASIC_INFO';
export const SET_CREATED_QUOTES: string = 'SET_CREATED_QUOTES';
export const INCREMENT_SAVE_PROPOSAL_ATTEMPTS: string = 'INCREMENT_SAVE_PROPOSAL_ATTEMPTS';
export const RESET_SAVE_PROPOSAL_ATTEMPTS: string = 'RESET_SAVE_PROPOSAL_ATTEMPTS';
export const SET_REVIEWED: string = 'SET_REVIEWED';
export const SET_CHANGE_AFTER_REVIEW: string = 'SET_CHANGE_AFTER_REVIEW';
export const SET_CONVERTED: string = 'SET_CONVERTED';
export const SET_QUOTED: string = 'SET_QUOTED';
