import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import config from '@/config';
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import cms from '@/store/modules/cms';
import products from '@/store/modules/products';
import proposal from '@/store/modules/proposal';
import quotation from '@/store/modules/quotation';
import EventBus from '@/common/EventBus';
import _ from 'lodash';

Vue.use(Vuex);
const debug = process.env.VUE_APP_ENV !== 'production';

const searchParams = window.location.search.slice(1).split('&');
const querySubSegment = (searchParams.find((s: any) => s.startsWith('subSegment')) || '').split('=')[1];
const querySegment = (searchParams.find((s: any) => s.startsWith('targetSegment')) || '').split('=')[1];
const segments = _.get(config, 'segments', '').toUpperCase().split(',');
const segment = window.location.pathname.replace(/^.*(coverhub-portal\/)?auth\//, '').split('/')[0].toUpperCase();
const pathSegment = (segments.includes(segment) || segments.includes('*')) && segment;

const storageName = (querySegment || pathSegment || window.sessionStorage.getItem('targetSegment') || '').toUpperCase();
(window as any).simpleSessionStorage = {};
let storage: any = {
	setItem: (item, value) => {
		(window as any).simpleSessionStorage[item] = value;
	},
	getItem: (item) => {
		return (window as any).simpleSessionStorage.hasOwnProperty(item) ? (window as any).simpleSessionStorage[item] : null;
	},
	removeItem: (item) => {
		delete (window as any).simpleSessionStorage[item];
	},
	clear: () => {
		(window as any).simpleSessionStorage = {};
	}
};
try {
	window.sessionStorage.setItem('targetSegment', storageName);
	if (querySubSegment) {
		window.sessionStorage.setItem('subSegment', querySubSegment);
	} else if (window.sessionStorage.getItem('subSegment')) {
		window.sessionStorage.removeItem('subSegment');
	}
	storage = window.sessionStorage;
} catch (e) {
	setTimeout(() => {
		EventBus.$emit('cookies-disabled');
	}, 3000);
}

export default new Vuex.Store({
	modules: {
		app,
		auth,
		cms,
		products,
		proposal,
		quotation
	},
	plugins: [
		createPersistedState({
			key: config.storageKey + '-' + storageName + (querySubSegment ? '-' + querySubSegment : ''),
			paths: ['app', 'auth', 'cms', 'products', 'proposal', 'quotation.caches'],
			storage
		})
	],
	strict: debug,
	devtools: debug
});
