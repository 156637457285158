import {GetterTree} from 'vuex';
import _ from 'lodash';
import {ICMS, IRoot} from '@/interfaces';
import Enums from '@/utils/Enums';
import Util from '@/utils/Util';
import {CMSContentService} from '@/services/CMSService';

const gettersImp: GetterTree<ICMS, IRoot> = {
  getMessageByCode: (state: ICMS) => (code: string) => {
    const message = _.find(state.messages, ['code', code]);
    const description = _.get(message, 'description', '');
    return description;
  },

  getProductContentByProduct: (state: ICMS) => (product: any) => {
    let cmsProduct = product;
    if (!_.get(product, 'fields')) {
      const key = Enums.CMS.PRODUCTS.METADATA.PROD_CODE;
      const products = state.products;
      const code = product.code;
      cmsProduct = _.find(products, (p: any) => {
        return p.fields.metadata.indexOf(`${key}:${code}`) > -1;
      });
    }
    return mapProductContent(cmsProduct);
  },

  getProductContentsByProduct: (state: ICMS) => (product: any) => {
    if (!_.get(product, 'fields')) {
      const key = Enums.CMS.PRODUCTS.METADATA.PROD_CODE;
      const products = state.products;
      const code = product.code;
      return _.filter(products, (p: any) => {
        return p.fields.metadata.indexOf(`${key}:${code}`) > -1;
      });
    }
    return [];
  },

  getReducedProducts: (state: ICMS, getters) => () => {
    return _.orderBy(state.products, ['fields.index'], ['asc']).map((product: any) => {
      return {
        ...product.fields,
        content: getters.getProductContentByProduct(product)
      };
    });
  },

  getComponentImportantInformation: (state: ICMS, getters, rootState: IRoot) => async (componentImportantInformation: string[]) => {
    const app = rootState.app;

    if (app.targetSegment) {
      const componentInformation: any[] = await CMSContentService.getComponentImportantInformation(app.targetSegment, app.language);
      const result: any = {};
      for (const i of componentInformation) {
        const exists = componentImportantInformation.find((id: string) => id === i.id);
        if (exists) {
          result[i.fields.code] = i.fields.text;
        }
      }

      return result;
    }

    return null;
  },

  getSurvey: (state: ICMS) => async (targetSegment: string, locale: string) => {
    const survey = CMSContentService.getSurvey(targetSegment, locale);
    return survey;
  },


  mapDataIncludes: (state: ICMS) => (data: any) => {
    // TODO: may refactor to have a more defined model, for now just map the assets
    const result = _.map(data.items, (item: any) => {
      const fields = item.fields;
      for (const key in fields) {
        if (key === 'additionalInformation') {
          const arr: any = [];
          _.each(fields[key], (info: any) => {
            const asset: any = _.find(data.includes.Asset, {
              sys: {id: info.sys.id},
            });
            if (asset) {
              arr.push(asset.fields);
            }
          });
          fields[key] = arr;
        } else if (key === 'questionnaire') {
          const arr: any = [];
          _.each(fields[key], (info: any) => {
            const asset: any = _.find(data.includes.Asset, {
              sys: {id: info.sys.id},
            });
            if (asset) {
              arr.push(asset.fields);
            }
          });
          fields[key] = arr;
        } else if (key === 'icon' || key === 'iconInverted' || key === 'productImage') {
          const obj = data.includes.Asset.find(
            (a: any) => a.sys.id === fields[key].sys.id,
          );
          const url = _.get(obj, 'fields.file.url', '');
          fields[key] = 'https://' + url.replace(/^.*\/\//, '');
        } else if (key === 'applicationForm') {
          const asset: any = _.find(data.includes.Asset, {
            sys: {id: fields[key].sys.id},
          });
          if (asset) {
            fields[key] = asset.fields;
          }
        }
      }
      return item;
    });
    return result;
  },

  mapProfilesAndLines: (state: ICMS) => (data: any) => {
    const arrResult: any = [];
    _.each(data.items, (item: any) => {
      const result: any = {
        group: {},
        products: []
      };
      const fields = item.fields;
      for (const key in fields) {
        if (key === 'profile' || key === 'line') {
          const info = fields[key];
          const entry: any = _.find(data.includes.Entry, {
            sys: {id: info.sys.id},
          });
          result.group = entry && entry.fields;
        } else if (key === 'products') {
          _.each(fields[key], (info: any) => {
            const entry: any = _.find(data.includes.Entry, {
              sys: {id: info.sys.id},
            });
            if (entry && entry.fields) {
              result.products = result.products.concat(entry.fields.metadata);
            }
          });
        }
      }
      arrResult.push(result);
    });
    return arrResult;
  },

  loadBasicInfoForm: (state: ICMS, getters, rootState: IRoot) => async (targetSegment: any) => {
    return await CMSContentService.getBasicInfo(targetSegment, _.get(rootState, 'app.language', 'en')).then(async (resp: any) => {
      return resp;
    });
  },
};

// private util functions
function getTransformedResponse(items: any, includes: any) {
  const textFields = items.fields.textFields
    ? items.fields.textFields.sys
      ? [items.fields.textFields.sys.id]
      : items.fields.textFields.map((field: any) => field.sys.id)
    : [];
  const checkboxes = items.fields.checkboxes
    ? items.fields.checkboxes.sys
      ? [items.fields.checkboxes.sys.id]
      : items.fields.checkboxes.map((field: any) => field.sys.id)
    : [];
  const infoTexts = items.fields.infoTexts
    ? items.fields.infoTexts.sys
      ? [items.fields.infoTexts.sys.id]
      : items.fields.infoTexts.map((field: any) => field.sys.id)
    : [];
  const data = {
    fields: items.fields,
    includes:
      includes &&
      includes.filter((include: any) => {
        const fieldId = include.sys.id;
        if (_.includes(textFields, fieldId)) {
          include.fields.type = 'textField';
          return true;
        }
        if (_.includes(checkboxes, fieldId)) {
          include.fields.type = 'checkbox';
          return true;
        }
        if (_.includes(infoTexts, fieldId)) {
          include.fields.type = 'infoText';
          return true;
        }
        return false;
      }),
  };
  return data;
}

function mapProductContent(product: any) {
  let result: any = {};
  if (_.get(product, 'fields')) {
    const fields = product.fields;
    result = {
      id: product.id,
      index: fields.index,
      selectable: fields.selectable,
      title: fields.title,
      description: fields.description,
      options: fields.options,
      importantInformation: fields.importantInformation,
      componentImportantInformation: fields.componentImportantInformation,
      startingPremiumLabel: fields.startingPremiumLabel,
      startingPremium: fields.startingPremium,
      benefits: fields.benefitsPointers,
      additionalInformation: fields.additionalInformation,
      catalogueDetailsOnly: fields.catalogueDetailsOnly,
      questionnaire: fields.questionnaire,
      applicationForm: fields.applicationForm,
      icon: fields.icon,
      iconInverted: fields.iconInverted,
      iconUrl: fields.icon.replace(/^.*\/\//, ''),
      productImageUrl: fields.productImage ? fields.productImage.replace(/^.*\/\//, '') : '',
      details: fields.details,
      action: fields.action,
      actionText: fields.actionText,
      actionTextSelected: fields.actionTextSelected
    };

    // code is exist on otherProduct model in contentful
    if (fields.code) {
      result.code = fields.code;
    }
  }
  return result;
}

function getBasicForm(includes: any[]) {
  const fields = includes.map((include: any) => {
    const hasColSpan =
      include.fields.metadata &&
      include.fields.metadata.filter((metadata: any) =>
        metadata.includes('COLSPAN:'),
      );
    if (hasColSpan && hasColSpan[0]) {
      include.fields.colspan = _.toInteger(hasColSpan[0].split('COLSPAN:')[1]);
    } else {
      include.fields.colspan = 1;
    }

    const meta = include.fields.metadata;
    include.fields.colspan = _.toInteger(
      hasMetadataAttribute(meta, 'COLSPAN:', 1),
    );
    include.fields.index = _.toInteger(
      hasMetadataAttribute(meta, 'INDEX:', -1),
    );
    include.fields.shiftFactIds = hasMetadataAttributes(meta, 'SHIFT_FACT_ID:');
    include.fields.pardotId = hasMetadataAttribute(meta, 'PARDOT_ID:', '');

    include.fields.validators = include.fields.validators
      ? include.fields.validators.join('|')
      : '';
    return include.fields;
  });
  return fields.sort((a: any, b: any) => (a.index > b.index ? 1 : -1));
}

function hasMetadataAttribute(metadata: any, name: string, defaultValue: any) {
  const metadataValue = metadata && metadata.filter((m: any) => m.includes(name));
  if (metadataValue[0]) {
    return metadataValue[0].split(name)[1];
  } else {
    return defaultValue;
  }
}

function hasMetadataAttributes(metadata: any, name: string) {
  const metadataValue = metadata && metadata.filter((m: any) => m.includes(name));
  const result: string[] = [];
  if (metadataValue) {
    for (const v of metadataValue) {
      const id = v.split(name)[1];
      result.push(id);
    }
  }
  return result;
}

export default gettersImp;
