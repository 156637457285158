import { GetterTree } from 'vuex';
import _ from 'lodash';
import config from '@/config';

import {IApp, IRoot} from '@/interfaces';

const gettersImp: GetterTree<IApp, IRoot> = {
  getValue: (state: IApp) => (code: string) => {
    return state[code];
  },
  getShiftToken: (state: IApp) => () => {
    try {
      const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      return (state.isContinuation || state.isRenewal || state.isSavedProposal || state.isBroker) ?
        sessionStorage.getItem(`login-token-${config.environment}-${ts}`) : localStorage.getItem(`guest-token-${config.environment}-${ts}`);
    } catch (e) {
      return null;
    }
  },
  getRenewalResponse: (state: IApp) => (id: string) => {
    return state.renewalResponse[id];
  },
  getPaymentProviders: (state: IApp) => () => {
    return state.paymentProviders;
  },
  getODConfig: (state: IApp) => (action: string, productCode?: string) => {
    const odLogConfig = _.get(state.config, `shiftMappings.OD_LOG.${action}`);
    if (productCode && odLogConfig) {
      return _.filter(odLogConfig, (c) => c.includes(productCode) || !c.includes('#'));
    } else {
      return odLogConfig;
    }
  }
};

export default gettersImp;
