import {ITheme, IColors} from '@/interfaces';
import { Colors } from '.';

export default class Theme implements ITheme {
  public targetSegment: string;
  public colors!: IColors;
  public cssObject: string;
  public logo: string;
  public logoDimensions: any;
  public footerLogo: string;
  public footerLogoDimensions: any;
  public segmentLogo: string;
  public segmentLogoDimensions: any;
  public covered: string;
  public adviser: string;
  public exclamation: string;
  public hero: string;
  public applicationHero: string;
  public successIcon: string;
  public failIcon: string;
  public clockIcon: string;
  public approved: string;
  public information: string;
  public question: string;
  public deleteIcon: string;
  public dateFormat: string;
  public ccWarningIcon: string;
  public errorIcon: string;
  public basicData: string;
  public configuration: string;
  public coverageDetails: string;
  public verification: string;
  public summary: string;
  public payment: string;
  public confirmation: string;
  public confirmationSkipped: string;
  public confirmationAdviser: string;
  public messageSent: string;

  constructor(
    targetSegment: string = '',
    colors: IColors = new Colors(),
    cssObject: string = '',
    logo: string = '',
    logoDimensions: any = {},
    segmentLogo: string = '',
    segmentLogoDimensions: any = {},
    footerLogo: string = '',
    footerLogoDimensions: any = {},
    covered: string = '',
    adviser: string = '',
    exclamation: string = '',
    hero: string = '',
    applicationHero: string = '',
    successIcon: string = '',
    failIcon: string = '',
    clockIcon: string = '',
    approved: string = '',
    information: string = '',
    question: string = '',
    deleteIcon: string = '',
    dateFormat: string = '',
    ccWarningIcon: string = '',
    errorIcon: string = '',
    basicData: string = '',
    configuration: string = '',
    coverageDetails: string = '',
    verification: string = '',
    summary: string = '',
    payment: string = '',
    confirmation: string = '',
    confirmationSkipped: string = '',
    confirmationAdviser: string = '',
    messageSent: string = ''
  ) {
    this.targetSegment = targetSegment;
    this.colors = colors;
    this.cssObject = cssObject;
    this.logo = logo;
    this.logoDimensions = logoDimensions;
    this.segmentLogo = segmentLogo;
    this.segmentLogoDimensions = segmentLogoDimensions;
    this.footerLogo = footerLogo;
    this.footerLogoDimensions = footerLogoDimensions;
    this.covered = covered;
    this.adviser = adviser;
    this.exclamation = exclamation;
    this.hero = hero;
    this.applicationHero = applicationHero;
    this.successIcon = successIcon;
    this.failIcon = failIcon;
    this.clockIcon = clockIcon;
    this.approved = approved;
    this.information = information;
    this.question = question;
    this.deleteIcon = deleteIcon;
    this.dateFormat = dateFormat;
    this.ccWarningIcon = ccWarningIcon;
    this.errorIcon = errorIcon;
    this.basicData = basicData;
    this.configuration = configuration;
    this.coverageDetails = coverageDetails;
    this.verification = verification;
    this.summary = summary;
    this.payment = payment;
    this.confirmation = confirmation;
    this.confirmationSkipped = confirmationSkipped;
    this.confirmationAdviser = confirmationAdviser;
    this.messageSent = messageSent;
  }
}
