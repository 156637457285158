import {ActionTree, ActionContext} from 'vuex';

import {
  RESET,
  SET_PRODUCTS,
  SET_BASIC_INFO,
  SET_COVERAGE_DATE,
  SET_PAYMENT_TOKEN,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  SET_PAYMENT_SKIPPED,
  SET_NO_PAYMENT,
  SET_QUOTE_NUMBER,
  SET_PROPOSAL_NUMBER,
  SET_PRODUCT_SESSION,
  SET_PRODUCT_POLICY_NUMBER,
  SET_PRODUCT_POLICY_ID,
  SET_PROPOSAL_INVOICE_ID,
  SET_INVOICES,
  SET_REFUND,
  SET_IS_ENDORSMENT,
  UPDATE_BASIC_INFO,
  SET_CREATED_QUOTES,
  INCREMENT_SAVE_PROPOSAL_ATTEMPTS,
  RESET_SAVE_PROPOSAL_ATTEMPTS, UPDATE_CONTACT_INFO, SET_CHANGE_AFTER_REVIEW, SET_REVIEWED, SET_CONVERTED, SET_QUOTED
} from './mutationTypes';
import {IProposal, IQuotationProduct, IRoot, IShiftProductData} from '@/interfaces';
import _ from 'lodash';

import Util from '@/utils/Util';
import {Product} from '@/models';
import QuoteService from '@/services/QuoteService';

const actions: ActionTree<IProposal, IRoot> = {
  init({commit, rootState, rootGetters}: ActionContext<IProposal, IRoot>, payload: any): any {
    const result: any = {};
    commit(RESET);

    const rawProducts = rootGetters['products/getProductsSortedLatest']();
    // Get production information based on selection
    const selectedProducts: any = [];

    // find product base on product id if productIds payload exist
    if (payload.productIds) {
      _.each(payload.productIds, (id: string) => {
        const rawProduct = rawProducts.find((p) => p.id === id);
        if (rawProduct) {
          const product = new Product({
            id: rawProduct.id,
            code: rawProduct.code,
            definition: _.get(rawProduct, 'definition')
          });
          selectedProducts.push(product);
        }
      });
    } else {
      _.each(payload.productCodes, (code: string) => {
        const rawProduct = rawProducts.find((p) => p.code === code);
        if (rawProduct) {
          const product = new Product({
            id: rawProduct.id,
            code: rawProduct.code,
            definition: _.get(rawProduct, 'definition')
          });
          selectedProducts.push(product);
        }
      });
    }

    commit(SET_PRODUCTS, selectedProducts);

    result.products = selectedProducts;
    return Promise.resolve(result);
  },

  setProductSession(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any,
  ) {
    commit(SET_PRODUCT_SESSION, payload);
  },

  setProductPolicyNumber(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any,
  ) {
    commit(SET_PRODUCT_POLICY_NUMBER, payload);
  },

  setProductPolicyId(
      {commit}: ActionContext<IProposal, IRoot>,
      payload: any,
  ) {
    commit(SET_PRODUCT_POLICY_ID, payload);
  },

  setBasicInfo(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    commit(SET_BASIC_INFO, payload);
  },

  updateContactInfo({commit, state, rootState}: ActionContext<IProposal, IRoot>, payload: Array<{ factID: string, value: string}>): void {
    payload.forEach((fact) => {
      const contactInfoFact = rootState.app.contactInfoFacts[fact.factID];
      if (contactInfoFact) {
        const contactInfoUpdatePayload = {};
        contactInfoUpdatePayload[contactInfoFact] = fact.value;
        commit(UPDATE_CONTACT_INFO, contactInfoUpdatePayload);
      }
    });
  },

  updateBasicInfo({commit, state, rootState, dispatch}: ActionContext<IProposal, IRoot>, payload: { factID: string, value: string}): void {
    if (state.basicInfo) {
      state.basicInfo.forEach((info: any, index: number) => {
        const factIds: string[] = info.shiftFactIds;
        if (factIds.includes(payload.factID) && payload.value) {
          commit(UPDATE_BASIC_INFO, { index, value: payload.value});
        }
      });
    }
    dispatch('proposal/updateContactInfo', [payload], { root: true});
  },

  setCoverageDate(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    const coverageStartDate = payload.coverageStartDate;
    let coverageEndDate;
    if (payload.coverageEndDate) {
      coverageEndDate = payload.coverageEndDate;
    } else {
      coverageEndDate = Util.addDate(
        coverageStartDate,
        payload.duration,
        payload.addType
      );
    }
    commit(SET_COVERAGE_DATE, {coverageStartDate, coverageEndDate});
  },

  async submitQuotes(
      {commit, state, rootState, rootGetters}: ActionContext<IProposal, IRoot>
  ): Promise<any> {
    const quotes: any[] = [];
    const quotedProducts: IShiftProductData[] = _.values(rootState.quotation.products);

    _.forEach(quotedProducts, async (product: IShiftProductData) => {
      const quoteInRenewalResponse: any = _.find(rootState.app.renewalResponse.raw, (q) => {
        const productId = rootState.app.renewalResponse.renewalIds[q.id];
        return productId === product.id && (q.type === Util.SHIFT_QUOTE_TYPE_RENEWAL || q.type === Util.SHIFT_QUOTE_TYPE_NEW);
      });
      if (quoteInRenewalResponse) {
        quotes.push({
          id: quoteInRenewalResponse.id,
          productId: rootState.app.renewalResponse.renewalIds[quoteInRenewalResponse.id],
          productConfig: product.storedSession,
          renewsPolicyId: quoteInRenewalResponse.renewsPolicyId,
          sfAccountId: quoteInRenewalResponse.sfAccountId,
          sfVersion: quoteInRenewalResponse.sfVersion
        });
      } else {
        quotes.push({
          productId: product.id,
          productConfig: product.storedSession
        });
      }
    });

    let quoteQuery;
    if (rootState.app.proposalId) {
      // get latest proposal
      const proposalResponse = await QuoteService.getQuotes(rootState.app.proposalId);
      const latestProposal = _.get(proposalResponse, 'data.data');
      const sfVersion = latestProposal.sfVersion;
      const existingQuotes = latestProposal.quotes;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < quotes.length; i++) {
        if (existingQuotes?.length > 0) {
          const eq = existingQuotes.find((quote: any) => quote.productId === quotes[i].productId);
          if (eq) {
            quotes[i].id = eq.id;
            quotes[i].sfVersion = eq.sfVersion;
          }
        }
      }
      quoteQuery = QuoteService.updateQuote(rootState.app.proposalId, {sfVersion, quotes});
    } else {
      quoteQuery = QuoteService.createQuote({quotes});
    }

    return quoteQuery
      .then((result: any) => {
        if (typeof result === 'string') {
          return result;
        }
        const proposalId = _.get(result, 'data.data.id');
        const createdQuotes = _.get(result, 'data.data.quotes');
        commit(SET_CREATED_QUOTES, createdQuotes);
        commit(SET_PROPOSAL_NUMBER, {proposalId});
      })
      .catch((err) => {
        throw err;
      });
  },

  setPaymentToken(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any
  ): void {
    commit(SET_PAYMENT_TOKEN, payload);
  },

  setPaymentSkipped(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: boolean
  ): void {
    commit(SET_PAYMENT_SKIPPED, payload);
  },

  setNoPayment(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: boolean
  ): void {
    commit(SET_NO_PAYMENT, payload);
  },

  setProposalInvoiceId(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any
  ): void {
    commit(SET_PROPOSAL_INVOICE_ID, payload);
  },

  addProduct(
    {commit, state}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    const isExist = state.products && state.products.find((p: Product) => p.id === payload.id);
    if (!isExist) {
      const product = new Product({
        id: payload.id,
        code: payload.code,
        definition: payload.definition
      });
      commit(ADD_PRODUCT, product);
    }
  },

  removeProduct(
    {commit, state}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    const index = _.findIndex(state.products, {id: payload.productID});
    commit(DELETE_PRODUCT, {index});
  },

  setInvoices(
    {commit, state}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    commit(SET_INVOICES, payload);
  },

  setRefund(
    {commit, state}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    commit(SET_REFUND, payload);
  },

  setIsEndorsment(
    {commit, state}: ActionContext<IProposal, IRoot>,
    payload: any,
  ): void {
    commit(SET_IS_ENDORSMENT, payload);
  },

  reset({commit}: ActionContext<IProposal, IRoot>): void {
    commit(RESET);
    commit(SET_BASIC_INFO, null);
  },

  incrementSaveProposalAttempts(
    {commit}: ActionContext<IProposal, IRoot>,
  ) {
    commit(INCREMENT_SAVE_PROPOSAL_ATTEMPTS);
  },

  resetSaveProposalAttempts(
    {commit}: ActionContext<IProposal, IRoot>,
  ) {
    commit(RESET_SAVE_PROPOSAL_ATTEMPTS);
  },

  setProposalNumber(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: any
  ): void {
    commit(SET_PROPOSAL_NUMBER, payload);
  },

  setReviewed(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: boolean
  ): void {
    commit(SET_REVIEWED, payload);
  },

  setChangeAfterReview(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: boolean
  ): void {
    commit(SET_CHANGE_AFTER_REVIEW, payload);
  },

  setConverted(
    {commit}: ActionContext<IProposal, IRoot>,
    payload: boolean
  ): void {
    commit(SET_CONVERTED, payload);
  },

  setQuoted({commit}: ActionContext<IProposal, IRoot>, payload: boolean): void {
    commit(SET_QUOTED, payload);
  },
};

function handleAssetLevelFact(assets: any[], id: string, value: any, index: number) {
  const asset = assets[index] || {facts: []};
  handleFact(asset.facts, id, value);
  assets[index] = asset;
}

function handleFact(facts: any[], id: string, value: any) {
  const fact: any | null | undefined = _.find(facts, (f) => f.id === id);
  if (fact) {
    fact.value = value;
  } else {
    facts.push({
      id,
      value
    });
  }
}

export default actions;
