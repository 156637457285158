import { MutationTree } from 'vuex';

import {SET_AUTH_CMS, RESET, SET_AUTH, SET_GUEST_PARTY} from './mutationTypes';
import {IAuth} from '@/interfaces';
import getInitialState from './initialState';
import {PolicyPartyModel} from 'shift-policy-service-api-client';

const mutations: MutationTree<IAuth> = {
	[SET_AUTH_CMS](state: IAuth, payload: any) {
		state.cms = {
			space: payload.space,
			accessToken: payload.accessToken
		};
	},

	[RESET](state: IAuth) {
		Object.assign(state, getInitialState());
	},

	[SET_AUTH](state: IAuth, payload: { userId: string, accountId: number }) {
		state.userId = payload.userId;
		state.accountId = payload.accountId;
	},

	[SET_GUEST_PARTY](state: IAuth, payload: PolicyPartyModel) {
		state.guestParty = payload;
	},

};

export default mutations;
