import _ from 'lodash';
import EventBus from '@/common/EventBus';

export default {

  requestInterceptor: (config) => {
    EventBus.$emit('api-request', {
      method: config.method,
      url: config.baseURL + config.url
    });
    return config;
  },

  responseInterceptor: (response) => {
    EventBus.$emit('api-done', {
      method: response.config.method,
      url: response.config.url
    });
    return response;
  },

  errorInterceptor: (err) => {
    // EventBus.$emit('api-error', {
    //   method: _.get(err, 'config.method'),
    //   url: _.get(err, 'config.url', ''),
    //   data: _.get(err, 'response.data')
    // });
    return Promise.reject(err);
  }
};
