import { MutationTree } from 'vuex';
import _ from 'lodash';
import { SET_PRODUCTS, SET_THEME, SET_LAYOUT, RESET_PRODUCTS, RESET, SET_OTHER_PRODUCTS, SET_MESSAGES, SET_BANNER } from './mutationTypes';
import { ICMS, ITheme } from '@/interfaces';
import getInitialState from './initialState';

const mutations: MutationTree<ICMS> = {
	[SET_PRODUCTS](state: ICMS, payload: any) {
		state.products = _.cloneDeep(payload);
	},

	[SET_OTHER_PRODUCTS](state: ICMS, payload: any) {
		state.otherProducts = _.cloneDeep(payload);
	},

	[SET_MESSAGES](state: ICMS, payload: any) {
		state.messages = _.cloneDeep(payload);
	},

	[RESET_PRODUCTS](state: ICMS) {
		state.products = [];
	},

	[RESET](state: ICMS) {
		Object.assign(state, getInitialState());
	},

	[SET_THEME](state: ICMS, payload: ITheme) {
		state.theme = payload;
	},

	[SET_LAYOUT](state: ICMS, payload: any) {
		state.layout = payload;
	},

  [SET_BANNER](state: ICMS, payload: string) {
		state.banner = payload;
	},
};

export default mutations;
