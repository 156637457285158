import { IProduct } from '@/interfaces';

export default class Product implements IProduct {
	public id: string = '';
	public code: string = '';
	public quoteId: string = '';
	public policyNumber: string = '';
	public policyId: string = '';
	public session: string = '';
	public definition: string = '';
	public productDescriptionId: string = '';

	constructor(payload: any) {
		this.id = payload.id;
		this.code = payload.code;
		this.definition = payload.definition;
	}
}
