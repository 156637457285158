import {GetterTree} from 'vuex';
import {IRawProduct, IRawProducts, IRoot} from '@/interfaces';
import _ from 'lodash';
import Enums from '@/utils/Enums';

const FACT_WITH_DEFAULT_VALUES = ['LIST', 'BOOLEAN', 'DATE'];

const gettersImp: GetterTree<IRawProducts, IRoot> = {
  getProductById: (state: IRawProducts) => (id: string) => {
    return _.find(state, {id});
  },
  getProductByCode: (state: IRawProducts) => (code: string) => {
    return _.find(state, {code});
  },
  getProductByCmsProduct: (state: IRawProducts) => (cmsProduct: any) => {
    const key = Enums.CMS.PRODUCTS.METADATA.PROD_CODE;
    const productCode = _.get(cmsProduct, 'fields.metadata', [])
      .find((f) => f.indexOf(`${key}:`) === 0) || '';
    const code = productCode.replace(/.*:/, '');
    return _.find(state, {code}) || {};
  },
  getAllProductsWithLatestVersion: (state: IRawProducts) => () => {
    const groupedProduct = _.groupBy(state, 'code');
    return _.map(groupedProduct, (products: IRawProduct[], productCode: string) => {
      const sortedProducts = _.orderBy(products, ['version', 'availableFrom'], ['desc']);
      return sortedProducts[0];
    });
  },
  getProductsSortedLatest: (state: IRawProducts) => () => {
    return _.orderBy(state, ['version', 'availableFrom'], ['desc']);
  }
};

export default gettersImp;
