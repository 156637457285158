import {GetterTree} from 'vuex';
import _ from 'lodash';
import {IProposal, IRoot} from '@/interfaces';
import utils from '@/views/auth/Portal/PortalStepperItems/Utils';

const UNDERWRITING_OK = 'OK';

const gettersImp: GetterTree<IProposal, IRoot> = {
  getBasicInfoValueByFactID: (state: IProposal) => (shiftFactID): string => {
    const foundBasicInfo = state.basicInfo ? _.find(state.basicInfo, (basicInfo: any) => basicInfo.shiftFactIds.includes(shiftFactID)) : null;
    return _.get(foundBasicInfo, 'value', '');
  },

  getAggProductInfoFields: (state: IProposal) => () => {
    return [];
  },

  getSelectedProducts: (
    state: IProposal,
    getters: any,
    rootState: IRoot,
  ) => () => {
    const products = _.map(state.products, (product: any) => {
      const cmsProduct = rootState.cms.products.find((p: any) =>
        p.fields.metadata.some((m: any) => m === 'PROD_CODE:' + product.code));
      const productIcon = _.get(cmsProduct, 'fields.icon', '');
      const productTitle = _.get(cmsProduct, 'fields.title', '');
      return {productTitle, productIcon, ...product};
    });
    return products;
  },

  getPaymentSkipped: (state: IProposal) => {
    return state.paymentSkipped;
  },

  getInvoicesPriceToPay: (
    state: IProposal,
    getters: any,
    rootState: IRoot
  ) => () => {
    let result = 0;
    _.forEach(state.invoices, (i: any) => {
      const status = i;
      result += (status.amount - status.taxAmount);
    });
    return result;
  },

  getInvoicesTotalTax: (
    state: IProposal,
    getters: any,
    rootState: IRoot
  ) => () => {
    let result = 0;
    _.forEach(state.invoices, (i: any) => {
      const status = i;
      result += status.taxAmount;
    });
    return result;
  },

  getProductSession: (state: IProposal) => (id: string) => {
    const prod = _.find(state.products, (p: any) => p.id === id);
    return _.get(prod, 'session', '');
  },

  underWritingResult: (state: IProposal) => () => {
    return utils.getUnderwritingResult(state.products);
  },

  invalidUnderWritingResult: (state: IProposal) => () => {
    const result = utils.getUnderwritingResult(state.products);
    return _.filter(result, (r: any) => r.underwritingResult !== UNDERWRITING_OK);
  },

  validUnderWritingResult: (state: IProposal) => () => {
    const result = utils.getUnderwritingResult(state.products);
    return _.filter(result, (r: any) => r.underwritingResult === UNDERWRITING_OK);
  }
};

export default gettersImp;
