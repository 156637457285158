import {MutationTree} from 'vuex';
import _, { create } from 'lodash';
import {
  RESET,
  SET_PRODUCTS,
  SET_BASIC_INFO,
  SET_COVERAGE_DATE,
  SET_PAYMENT_TOKEN,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  SET_PAYMENT_SKIPPED,
  SET_NO_PAYMENT,
  SET_QUOTE_NUMBER,
  SET_PRODUCT_SESSION,
  SET_PROPOSAL_NUMBER,
  SET_PRODUCT_POLICY_NUMBER,
  SET_PRODUCT_POLICY_ID,
  SET_PROPOSAL_INVOICE_ID,
  SET_INVOICES,
  SET_REFUND,
  SET_IS_ENDORSMENT,
  UPDATE_BASIC_INFO,
  SET_CREATED_QUOTES,
  INCREMENT_SAVE_PROPOSAL_ATTEMPTS,
  RESET_SAVE_PROPOSAL_ATTEMPTS, UPDATE_CONTACT_INFO, SET_CHANGE_AFTER_REVIEW, SET_REVIEWED, SET_CONVERTED, SET_QUOTED
} from './mutationTypes';
import { IProposal, IBasicInfoUpdatePayload } from '@/interfaces';
import {Product} from '@/models';
import getInitialState from './initialState';

const mutations: MutationTree<IProposal> = {
  [SET_PRODUCTS](state: IProposal, payload: any) {
    state.products = _.cloneDeep(payload);
  },

  [UPDATE_CONTACT_INFO](state: IProposal, payload: any) {
    state.contactInfo = {
      ...state.contactInfo,
      ...payload
    };
  },

  [SET_BASIC_INFO](state: IProposal, payload: any) {
    state.basicInfo = payload;
  },

  [SET_COVERAGE_DATE](state: IProposal, payload: any) {
    state.coverageStartDate = payload.coverageStartDate;
    state.coverageEndDate = payload.coverageEndDate;
  },

  [SET_PRODUCT_SESSION](state: IProposal, payload: any) {
    const productId = payload.id;
    const session = payload.productSession;

    state.products = _.forEach(state.products, (product: Product) => {
      if (productId === product.id) {
        product.session = session;
      }
    });
  },

  [SET_PRODUCT_POLICY_NUMBER](state: IProposal, payload: any) {
    const productId = payload.productId;
    const policyNumber = payload.policyNumber;

    _.each(state.products, (product: Product) => {
      if (productId === product.id) {
        product.policyNumber = policyNumber;
      }
    });
    state.products = [].concat(state.products);
  },

  [SET_PRODUCT_POLICY_ID](state: IProposal, payload: any) {
    const productId = payload.productId;
    const policyId = payload.policyId;

    _.each(state.products, (product: Product) => {
      if (productId === product.id) {
        product.policyId = policyId;
      }
    });
    state.products = [].concat(state.products);
  },

  [SET_QUOTE_NUMBER](state: IProposal, payload: any) {
    const productId: string = payload.productId;
    const quoteId: string = payload.quoteId;
    _.each(state.products, (product: Product) => {
      if (product.id === productId) {
        product.quoteId = quoteId;
      }
    });
  },

  [SET_PROPOSAL_NUMBER](state: IProposal, payload: any) {
    state.proposalId = payload.proposalId;
  },

  [SET_PAYMENT_TOKEN](state: IProposal, payload: any) {
    state.paymentToken = payload;
  },

  [SET_PROPOSAL_INVOICE_ID](state: IProposal, payload: any) {
    state.invoiceId = payload;
  },

  [ADD_PRODUCT](state: IProposal, payload: any) {
    const product = _.cloneDeep(payload);
    state.products.push(product);
  },

  [DELETE_PRODUCT](state: IProposal, payload: any) {
    state.products.splice(payload.index, 1);
  },

  [SET_INVOICES](state: IProposal, payload: any) {
    state.invoices = payload;
  },

  [SET_REFUND](state: IProposal, payload: any) {
    state.refundAmt = payload.refund;
    state.refundTaxAmt = payload.refundTax;
  },

  [SET_IS_ENDORSMENT](state: IProposal, payload: boolean) {
    state.isEndorsment = payload;
  },

  [RESET](state: IProposal) {
    Object.assign(state, getInitialState());
  },

  [SET_PAYMENT_SKIPPED](state: IProposal, payload: any) {
    state.paymentSkipped = payload;
  },

  [UPDATE_BASIC_INFO](state: IProposal, payload: IBasicInfoUpdatePayload) {
    state.basicInfo[payload.index].value = payload.value;
  },

  [SET_NO_PAYMENT](state: IProposal, payload: any) {
    state.noPayment = payload;
  },

  [SET_CREATED_QUOTES](state: IProposal, payload: any[]) {
    state.createdQuotes = payload;
    payload.forEach((createdQuote: any) => {
      const productIndex: number = _.findIndex(state.products, (p: Product) => p.id ===  createdQuote.productId);
      if (productIndex >= 0) {
        state.products[productIndex].quoteId = createdQuote.id;
      }
    });
  },

  [INCREMENT_SAVE_PROPOSAL_ATTEMPTS](state: IProposal) {
    state.saveProposalAttempts++;
  },

  [RESET_SAVE_PROPOSAL_ATTEMPTS](state: IProposal) {
    state.saveProposalAttempts = 0;
  },

  [SET_REVIEWED](state: IProposal, payload: boolean) {
    state.reviewed = payload;
  },

  [SET_CHANGE_AFTER_REVIEW](state: IProposal, payload: boolean) {
    state.changeAfterReview = payload;
  },

  [SET_CONVERTED](state: IProposal, payload: boolean) {
    state.converted = payload;
  },

  [SET_QUOTED](state: IProposal, payload: boolean) {
    state.quoted = payload;
  },
};

export default mutations;
