import { ICMS } from '@/interfaces';

export default (): ICMS => {
	return {
		products: [],
		otherProducts: [],
		messages: [],
		theme: null,
    banner: '',
		layout: null
	};
};
