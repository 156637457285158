const ODLog = (payload: any) => {
	(window as any).dataLayer = (window as any).dataLayer || [];
	(window as any).dataLayer.push(payload);
};

export default class GTMUtil {
    public static logGTMEvent(self, action, category, label, value) {
        if (!self.$gtm || !self.$gtm.trackEvent) {
            return;
        }
        ODLog(value);
        self.$gtm.trackEvent({
            event: label,
            category,
            action,
            label,
            value,
          });
    }
}
