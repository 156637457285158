export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const SET_DETECTED_COUNTRY: string = 'SET_DETECTED_COUNTRY';
export const SET_TARGET_SEGMENT: string = 'SET_TARGET_SEGMENT';
export const SET_CLIENT_ID: string = 'SET_CLIENT_ID';
export const SET_PROFILE: string = 'SET_PROFILE';
export const SET_PAYMENT_PROVIDERS: string = 'SET_PAYMENT_PROVIDERS';
export const SET_LINE: string = 'SET_LINE';
export const SET_AREA_OF_SERVICE: string = 'SET_AREA_OF_SERVICE';
export const SET_PRODUCTS: string = 'SET_PRODUCTS';
export const SET_STEPPER_ACTIVE_STEP: string = 'SET_STEPPER_ACTIVE_STEP';
export const SET_STEPPER_TOTAL_STEPS: string = 'SET_STEPPER_TOTAL_STEPS';
export const SET_FOOTER_VISIBILITY: string = 'SET_FOOTER_VISIBILITY';
export const SET_POLICY_IDS: string = 'SET_POLICY_IDS';
export const SET_BUNDLE_ID: string = 'SET_BUNDLE_ID';
export const SET_ERROR: string = 'SET_ERROR';
export const SET_VALUE: string = 'SET_VALUE';
export const SET_LOADING_TEXT: string = 'SET_LOADING_TEXT';
export const SET_IS_CONTINUATION: string = 'SET_IS_CONTINUATION';
export const RESET: string = 'RESET';
export const SET_RENEWAL_RESPONSE: string = 'SET_RENEWAL_RESPONSE';
export const SET_PROPOSAL_NUMBER: string = 'SET_PROPOSAL_NUMBER';
export const SET_PAY_BUTTON_VISIBLE: string = 'SET_PAY_BUTTON_VISIBLE';
export const SET_AFFILIATE_CODE: string = 'SET_AFFILIATE_CODE';
export const SET_CONTENT_READY: string = 'SET_CONTENT_READY';
export const SET_PE_PROGRESS_STATUS: string = 'SET_PE_PROGRESS_STATUS';
