import EventBus from '@/common/EventBus';
import config from '@/config';

export class IdleService {

  public static resetTimer() {
    window.clearTimeout(IdleService.timeoutID);
    IdleService.timeoutID = window.setTimeout(IdleService.goInactive, (config as any).timeout);
  }

  private static timeoutID: any;

  private static goInactive() {
    EventBus.$emit(`went-idle`);
  }
}
