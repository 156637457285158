import { MutationTree } from 'vuex';
import { IRawProducts } from '@/interfaces';
import {LOAD, RESET, ADD} from './mutationTypes';
import _SortBy from 'lodash/sortBy';
import _FindIndex from 'lodash/findIndex';
import _CloneDeep from 'lodash/cloneDeep';

const mutations: MutationTree<IRawProducts> = {
	[LOAD](state: IRawProducts, payload: any) {
		const products = payload.products || payload;
		let temp = _CloneDeep(state);
		temp.splice(0, temp.length);
		temp.push.apply(temp, products);
		if (payload.order) {
			temp = _SortBy(temp, (p) => payload.order.indexOf(p.code));
		}
		state.splice(0, state.length);
		state.push.apply(state, temp);
	},

	[ADD](state: IRawProducts, payload: any) {
		const prod = payload.product || payload;
		let temp = _CloneDeep(state);
		const index = _FindIndex(temp, (product) => product.id === prod.id);
		if (index >= 0) {
			temp[index] = prod;
		} else {
			temp.push(prod);
		}
		if (payload.order) {
			temp = _SortBy(temp, (p) => payload.order.indexOf(p.code));
		}
		state.splice(0, state.length);
		state.push.apply(state, temp);
	},

	[RESET](state: IRawProducts) {
		state.splice(0, state.length);
	}
};

export default mutations;
