import { APIResource, APIError } from '@/utils/APIResources';
import { AxiosError } from 'axios';
import { V1WorkflowApiAxiosParamCreator, RunWorkflowProcessRequestModel } from 'shift-workflow-service-api-client';

const service = V1WorkflowApiAxiosParamCreator();

export default class {
  public static async runWorkflowSync(workflowProcessKey: string, context: any, outputVariableNames: string[]) {
    const req = await service.runWorkflowSync({
      context,
      outputVariableNames,
      workflowProcessKey,
      runTimeoutMillis: 30000
    });
    return APIResource.post(req.url, req.options.data, req.options)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }


}
