import {ActionTree, ActionContext} from 'vuex';
import merge from 'deepmerge';
import config from '@/config/index';

import {IAuth, IRoot} from '@/interfaces';
import {SET_AUTH} from '@/store/modules/auth/mutationTypes';
import PolicyService from '@/services/PolicyService';
import {SET_GUEST_PARTY} from '@/store/modules/auth/mutationTypes';

const actions: ActionTree<IAuth, IRoot> = {
  async cmsAuthentication(
    {commit, rootState}: ActionContext<IAuth, IRoot>,
    payload: any,
  ): Promise<any> {
    const base = await import(`@/settings/segment/base.json`);
    const segmentBase = await import(`@/settings/segment/${payload.targetSegment.toUpperCase()}/index.json`);
    let segmentConfig: any =  merge(base.default, segmentBase.default);

    if (payload.language) {
      await import(`@/settings/segment/${payload.targetSegment.toUpperCase()}/${payload.language}.json`)
        .then((segmentWithLang: any) => {
          segmentConfig = merge(segmentConfig, segmentWithLang.default);
        })
        .catch((error) => {
          console.error('loading segment config error', error);
        });
    }

    // segment with env
    const environment = config?.environment;
    if (environment) {
      await import(`@/settings/segment/${payload.targetSegment.toUpperCase()}/${environment}/index.json`).catch((error) => {
        console.error('loading segment config environment error', error);
      })
      .then((segmentWithEnv: any) => {
        segmentConfig = merge(segmentConfig, segmentWithEnv.default);
      });
    }

    if (segmentConfig && segmentConfig.targetSegment === payload.targetSegment) {
      // debug segment config
      if (!['production'].includes(process.env.VUE_APP_ENV)) {
        console.log('segment config:', { segmentConfig });
      }
      return Promise.resolve(segmentConfig);
    } else {
      console.error('Unable to retrieve targetSegment information');
    }
  },
  async setAuth(
      {commit}: ActionContext<IAuth, IRoot>,
      payload: { accountId: number, userId: string, isGuest: boolean }
  ) {
    if (payload.isGuest) {
      const guestParty = await PolicyService.getGuestPolicyPartyForUserId(payload.userId);
      if (guestParty) {

        commit('auth/' + SET_GUEST_PARTY, guestParty, { root: true});
      }
    }
    commit(SET_AUTH, { userId: payload.userId, accountId: payload.accountId });
  }
};

export default actions;
