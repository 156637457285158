import { IColors } from '@/interfaces';
import _ from 'lodash';

export default class Colors implements IColors {
	public primary: string = '#aaa';
	public primaryGradient: string = '#aaa';
	public secondary: string = '#aaa';
	public secondaryGradient: string = '#aaa';
	public accent: string = '#aaa';
	public error: string = '#aaa';
	public info: string = '#aaa';
	public success: string = '#aaa';
	public warning: string = '#aaa';
	public border: string = '#444';
	public banner: string = '#000';
	public productBar: string = '#aaa';
	public productBarTitle: string = '#000';
	public productBarDescription: string = '#000';
	public productBarActive: string = '#aaa';
	public productBarTitleActive: string = '#000';
	public topBar: string = '#fff';
	public topBarBorder: string = '#444';
	public topBarLink: string = '#000';
	public background: string = '#fff';
	public filterColor: string = '#444';
	public disclaimer: string = '#000';
	public secondaryButton = '#aaa';
	public secondaryButtonFont = '#000';
	public secondaryButtonBorder = '#000';
	public mainButton: string = '#aaa';
	public mainButtonFont: string = '#000';
	public mainButtonBorder: string = '#000';
	public addButtonFont: string = '#000';
	public helpButton: string = '#aaa';
  public helpButtonFont: string = '#000';
  public helpButtonBorder: string = '#000';
  public applicationButton: string = '#aaa';
  public applicationButtonFont: string = '#000';
  public applicationButtonBorder: string = '#000';
	public fontsPrimary: string = '#000';
	public fontsSecondary: string = '#000';
	public fontsLight: string = '#000';
	public fontsLighter: string = '#000';
	public fontsHeading: string = '#000';
	public fontsInfo: string = '#000';
	public infoHeader: string = '#000';
	public price: string = '#000';
	public footer: string = '#000';
	public footerText: string = '#000';
	public heroTitle: string = '#000';
	public heroMidtitle: string = '#000';
	public heroSubtitle: string = '#000';
	public recommend: string = '#000';
	public recommendStar: string = '#000';
	public enumeration: string = '#000';
	public importantInformationBackground: string = '#fff';
	public importantInformationBorder: string = '#000';
	public sectionHeading: string | null = null;
	public sectionHeadingGradient: string | null = null;
	public footerLinkColor: string | null = null;
	public footerLinkDivider: string | null = null;

	constructor(
		colors = {}
	) {
    _.forEach(colors, (color, name) => {
      this[name] = color;
    });
	}
}
