import {ActionTree, ActionContext} from 'vuex';

import {
  SET_LANGUAGE,
  SET_DETECTED_COUNTRY,
  SET_TARGET_SEGMENT,
  SET_CLIENT_ID,
  SET_PROFILE,
  SET_LINE,
  SET_AREA_OF_SERVICE,
  SET_PRODUCTS,
  SET_PAYMENT_PROVIDERS,
  SET_STEPPER_ACTIVE_STEP,
  SET_STEPPER_TOTAL_STEPS,
  SET_FOOTER_VISIBILITY,
  SET_POLICY_IDS,
  SET_BUNDLE_ID,
  SET_ERROR,
  SET_VALUE,
  SET_LOADING_TEXT,
  SET_IS_CONTINUATION,
  SET_RENEWAL_RESPONSE,
  SET_PROPOSAL_NUMBER,
  SET_PAY_BUTTON_VISIBLE,
  SET_AFFILIATE_CODE,
  SET_CONTENT_READY,
  SET_PE_PROGRESS_STATUS
} from './mutationTypes';
import {IApp, IRoot} from '@/interfaces';
import QuoteService from '@/services/QuoteService';
import _ from 'lodash';
import PolicyService from '@/services/PolicyService';
import Util from '@/utils/Util';

const actions: ActionTree<IApp, IRoot> = {
  init({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    if (payload.language) {
      commit(SET_LANGUAGE, payload.language);
    } else if (payload.languages) {
      commit(SET_LANGUAGE, payload.languages);
    }
    commit(SET_TARGET_SEGMENT, {
      targetShiftClientId: payload.targetShiftClientId,
      targetSegment: payload.targetSegment,
      languages: payload.languages,
      journeyLanguages: payload.journeyLanguages,
      showRenewLink: payload.showRenewLink,
      showSaveProposalButton: payload.showSaveProposalButton,
      showSaveProposalButtonOnValidPrice: payload.showSaveProposalButtonOnValidPrice,
      showDatesSection: payload.showDatesSection,
      renewalSkipCatalogue: payload.renewalSkipCatalogue,
      showRequestButton: payload.showRequestButton,
      homeUrl: payload.homeUrl,
      catalogueUrl: payload.catalogueUrl,
      adviserPhone: payload.adviserPhone,
      adviserEmail: payload.adviserEmail,
      helpAddress: payload.helpAddress,
      helpPhone: payload.helpPhone,
      helpFax: payload.helpFax,
      helpEmail: payload.helpEmail,
      downloadAddress: payload.downloadAddress,
      downloadFax: payload.downloadFax,
      config: payload.config,
      renewalResponse: {},
      helpAddresses: payload.helpAddresses,
      copyCoverageDate: _.get(payload.config, 'copyCoverageDate.default'),
      contactInfoFacts: payload.contactInfoFacts ? Object.keys(payload.contactInfoFacts).reduce((acc, cur) => {
        const fact = payload.contactInfoFacts[cur];
        acc[fact] = cur;
        return acc;
      }, {}) : undefined,
    });
    if (payload.profile) {
      commit(SET_PROFILE, payload.profile);
    }
    if (payload.line) {
      commit(SET_LINE, payload.line);
    }
    if (payload.areaOfService) {
      commit(SET_AREA_OF_SERVICE, payload.areaOfService);
    }
    if (payload.products) {
      commit(SET_PRODUCTS, payload.products);
    }
  },

  initPaymentProviders({commit, state}: ActionContext<IApp, IRoot>) {
    if (state.config.paymentProvider) {
      return;
    }
    PolicyService.getPaymentProviders()
    .then((result: any) => {
      const providers = _.get(result, 'data.data');
      commit(SET_PAYMENT_PROVIDERS, providers);
    })
    .catch((err) => {
      throw err;
    });
  },

  setTargetSegment({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    commit(SET_TARGET_SEGMENT, { targetSegment: payload.targetSegment });
  },

  setClientId({commit}: ActionContext<IApp, IRoot>, clientId: any): void {
    commit(SET_CLIENT_ID, clientId);
  },

  setDetectedCountry({commit}: ActionContext<IApp, IRoot>, country: string): void {
    commit(SET_DETECTED_COUNTRY, country);
  },

  setProfile({commit}: ActionContext<IApp, IRoot>, profile: string): void {
    commit(SET_PROFILE, profile);
  },

  setAffiliateCode({commit}: ActionContext<IApp, IRoot>, code: string): void {
    commit(SET_AFFILIATE_CODE, code);
  },

  setLanguage({commit}: ActionContext<IApp, IRoot>, language: string): void {
    commit(SET_LANGUAGE, language);
  },

  setProducts({commit}: ActionContext<IApp, IRoot>, products: string[]): void {
    commit(SET_PRODUCTS, products);
  },

  setActiveStep({commit}: ActionContext<IApp, IRoot>, step: string): void {
    commit(SET_STEPPER_ACTIVE_STEP, step);
  },

  setTotalSteps({commit}: ActionContext<IApp, IRoot>, steps: number): void {
    commit(SET_STEPPER_TOTAL_STEPS, steps);
  },

  setFooterVisibility({commit}: ActionContext<IApp, IRoot>, status: boolean): void {
    commit(SET_FOOTER_VISIBILITY, status);
  },

  setPolicyIds({commit}: ActionContext<IApp, IRoot>, policyIds: string[]): void {
    commit(SET_POLICY_IDS, policyIds);
  },

  setBundleId({commit}: ActionContext<IApp, IRoot>, bundleId: string): void {
    commit(SET_BUNDLE_ID, bundleId);
  },

  setError({commit}: ActionContext<IApp, IRoot>, error: string): void {
    commit(SET_ERROR, error);
  },

  setValue({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    commit(SET_VALUE, {code: payload.code, value: payload.value});
  },

  setLoadingText({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    if (!payload) {
      commit(SET_LOADING_TEXT, null);
    } else {
      commit(SET_LOADING_TEXT, payload.message.replace('{0}', payload.productName));
    }
  },

  setIsContinuation({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    commit(SET_IS_CONTINUATION, payload);
  },

  setPardotAvailability({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    commit(SET_VALUE, {code: 'pardotAvailability', value: payload});
  },

  reset({commit}: ActionContext<IApp, IRoot>, includingAuth: boolean = true): void {
    commit('app/RESET', null, {root: true});
    if (includingAuth) {
      commit('auth/RESET', null, {root: true});
    }
    commit('products/RESET', null, {root: true});
    commit('proposal/RESET', null, {root: true});
    commit('cms/RESET', null, {root: true});
  },

  resetRenewalResponse({commit}: ActionContext<IApp, IRoot>): void {
    commit(SET_RENEWAL_RESPONSE, {});
  },

  setRenewalResponse({commit}: ActionContext<IApp, IRoot>, payload: any): void {
    commit(SET_RENEWAL_RESPONSE, payload);
  },

  getQuotes(
    {commit, state, rootGetters}: ActionContext<IApp, IRoot>, proposalId: string
  ): any {
    return QuoteService.getQuotes(proposalId)
      .then((result: any) => {
        const createdQuotes = _.get(result, 'data.data.quotes');
        const renewalIds: any = {};
        const nonRenewalIds: any = {};
        const configs = {};

        _.forEach(createdQuotes, (quote: any) => {
            renewalIds[quote.id] = quote.productId;
            if (quote.type === Util.SHIFT_QUOTE_TYPE_NEW) {
              nonRenewalIds[quote.id] = quote.productId;
            }
            configs[quote.productId] = quote.productConfig;
        });

        if (createdQuotes) {
          const data = JSON.parse(createdQuotes[0].productConfig);
          commit(SET_RENEWAL_RESPONSE, {
            renewalIds,
            nonRenewalIds,
            configs,
            sfVersion: _.get(result, 'data.data.sfVersion'),
            raw: createdQuotes
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  setProposalNumber(
    {commit}: ActionContext<IApp, IRoot>,
    payload: any
  ): void {
    commit(SET_PROPOSAL_NUMBER, payload);
  },

  setPayButtonVisible({commit}: ActionContext<IApp, IRoot>, payload: boolean): void {
    commit(SET_PAY_BUTTON_VISIBLE, payload);
  },

  setContentReady({commit}: ActionContext<IApp, IRoot>, payload: boolean): void {
    commit(SET_CONTENT_READY, payload);
  },

  setPeProgress({commit}: ActionContext<IApp, IRoot>, payload: boolean): void {
    commit(SET_PE_PROGRESS_STATUS, payload);
  }
};

export default actions;
