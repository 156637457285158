import {APIResource, APIError} from '@/utils/APIResources';
import {AxiosError} from 'axios';
import {V2ProductApiAxiosParamCreator} from 'shift-product-service-api-client';

const service = V2ProductApiAxiosParamCreator();

export default class {
	public static async list() {
		const req = await service.getAvailableProducts();
		return APIResource.get(req.url, req.options)
			.catch((error: AxiosError) => {
				return Promise.reject(new APIError(error));
			});
	}

	public static async getProduct(productId: string) {
		const req = await service.getProduct(productId);
		return APIResource.get(req.url, req.options)
			.catch((error: AxiosError) => {
				return Promise.reject(new APIError(error));
			});
	}
}
