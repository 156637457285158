import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import config, { isSentryEnabled } from '@/config';

if (isSentryEnabled()) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.environment,
    release: process.env.VUE_APP_PACKAGE + '@' + process.env.VUE_APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing(),
      new SentryIntegrations.Vue({ Vue, attachProps: true }),
      // make console.error a sentry error instead of just breadcrumb
      new SentryIntegrations.CaptureConsole({ levels: ['error'] })
    ],
    debug: true,

  });
}
