import config from '@/config';
import axios, { AxiosError } from 'axios';
import {APIError} from '@/utils/APIResources';

export default class {
  public static authGuest(shiftClientId: string) {
    const api = window.location.origin + config.api.publicPath + '/v2/auth/guest';
    const payload = {
      clientId: shiftClientId,
      callback: `${window.location.origin}${config.api.callback}` + '?type=guest'
    };
    return axios.post(api, payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static authLogin(shiftToken: string, shiftClientId: string, linkType: string) {
    const api = window.location.origin + config.api.publicPath + '/v2/auth/login';
    const payload = {
      token: shiftToken,
      clientId: shiftClientId,
      callback: `${window.location.origin}${config.api.callback}` + '?type=login&' + linkType + '=true'
    };
    return axios.post(api, payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static oidcLogin(clientId: string) {
    const api = window.location.origin + config.api.publicPath + '/v2/auth/login';
    const callback = `${window.location.origin}${config.api.callback}`;
    const payload = {
      clientId,
      callback,
      oidcPortalRedirect: true
    };
    return axios.post(api, payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static oidcCallback(code: string, state: string) {
    const api = window.location.origin + config.api.publicPath + '/v2/auth/callback';
    const callback = `${window.location.origin}${config.api.callback}`;
    const payload = { code, state, callback };
    return axios.post(api, payload)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }
}
