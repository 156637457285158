import { AxiosError } from 'axios';
import SentryService from '@/services/SentryService';
import _ from 'lodash';

class ApiError extends Error {
  public name: string = 'ApiError';
  public code: number | null = null;
  public message: string = '';
  public stack?: string = '';
  public defaultMessage: string | null = null;
  public details: object = {};
  public fail: boolean;
  public type: string | null = null;

  public constructor(err: AxiosError) {
    super();
    this.stack = err.stack ? err.stack : '';
    const response: any = err.response;
    let error: any;
    this.fail = true;
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        this.code = response.status;
        this.message = response.message;
        this.defaultMessage = response.statusText;
        this.details = response;
        this.fail = false;
      } else if (response.status === 404) {
        this.code = response.status;
        this.message = 'API not found';
        this.defaultMessage = response.statusText;
      } else if (response.status === 401) {
        this.code = response.status;
        this.message = 'Your session has expired. Please login again to resume.';
        this.defaultMessage = response.statusText;
      } else if (response.status === 402) {
        this.code = response.status;
        this.message = 'Your payment has encountered an issue. Please try again.';
        this.defaultMessage = response.statusText;
      } else if (response.status === 400) {
        this.code = response.status;
        this.message = response.data?.error?.message;
        this.defaultMessage = response.statusText;
      } else if (response.status === 500) {
        this.code = response.status;
        this.message = response.data?.error?.message;
        this.defaultMessage = response.statusText;
      } else {
        const appError = {
          type: response.data && response.data.error,
          message: response.data && response.data.error_description,
          defaultMessage: response.statusText,
        };

        this.code = response.status;
        this.message = appError.message;
        this.defaultMessage = appError.defaultMessage;
        this.type = appError.type;
      }
    }
    const resp: any = { ...err.response };
    delete resp.data;
    error = {
      defaultError: resp,
      code: this.code,
      message: this.message,
      defaultMessage: this.defaultMessage,
      type: this.type,
      fail: this.fail,
    };

    if (error.fail) {
      const errorData = _.get(err, 'response.data.error', 'error?');
      SentryService.logException({ type: 'SHIFT', error: errorData, data: error, level: 'error' });
    }
    return error;
  }
}

export const APIError = ApiError;
