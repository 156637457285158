import { IProposal } from '@/interfaces';

export default (): IProposal => {
  return {
    proposalId: '',
    productLevelFacts: [],
    products: [],
    basicInfo: [],
    contactInfo: {
      givenName: '',
      familyName: '',
      email: '',
      phone: ''
    },
    coverageStartDate: new Date(),
    coverageEndDate: new Date(),
    paymentSkipped: false,
    noPayment: false,
    paymentToken: '',
    invoiceId: '',
    refundAmt: Number.NaN,
    refundTaxAmt: Number.NaN,
    invoices: [],
    isEndorsment: false,
    createdQuotes: [],
    paymentLink: '',
    saveProposalAttempts: 0,
    reviewed: false,
    changeAfterReview: true,
    converted: false,
    quoted: false,
  };
};
