import { IQuotation } from '@/interfaces';

export default (): IQuotation => {
	return {
		products: {},
		caches: [],
		synonyms: {},
		shiftResults: [],
		filesToUpload: {}
	};
};
