import { IAuth } from '@/interfaces';

export default (): IAuth => {
	return {
		cms: {
			space: null,
			accessToken: null
		},
		userId: null,
		accountId: null,
		guestParty: null,
	};
};
