import { GetterTree } from 'vuex';

import {IAuth, IRoot} from '@/interfaces';

const gettersImp: GetterTree<IAuth, IRoot> = {
    getUserId: (state: IAuth) => () => {
        return state.userId;
    },
    getGuestParty: (state: IAuth) =>  () => state.guestParty,
    getAccountId: (state: IAuth) =>  () => state.accountId,
};

export default gettersImp;
