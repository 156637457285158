import VueI18n from 'vue-i18n';
import Vue from 'vue';
import {fallback} from '@/locales';

Vue.use(VueI18n);

const defaultLocale = 'en';
// Create VueI18n instance with options
const i18n = new VueI18n({
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  locale: defaultLocale,
  messages: fallback
});

export {i18n, defaultLocale};
