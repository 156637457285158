import { APIError, APIResource } from '@/utils/APIResources';
import axios, { AxiosError } from 'axios';
import { V1ContentApiAxiosParamCreator } from 'shift-content-mgmt-service-api-client';

const service = V1ContentApiAxiosParamCreator();

export default class {

  public static async uploadfile(data: any, accountId: any, productId: string) {

    const payloadType = data.type.includes('csv') ? 'text/plain' : data.type;
    const binary: any = await this.readFileAsync(data);
    const metadata = {
      'content-Type':  data.type.includes('csv') ? 'text/plain' : data.type,
      sfAccountId: accountId ? accountId : -1
    };

    const req =  await service.uploadObject(payloadType, data.name, binary, JSON.stringify(metadata), productId);

    return APIResource.post(req.url, binary, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  private static readFileAsync(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
}
