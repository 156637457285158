import Vue from 'vue';
import { themes } from '@/styles/themes';
import store from '@/store';
import EventBus from '@/common/EventBus';

const vue = new Vue();

const isInViewport = (e, {top: t, height: h} = e.getBoundingClientRect()) => t <= innerHeight && t + h >= 0;

Vue.prototype.$pardot = async () => {
	try {
		return (await require('@/services/PardotService.ts')).PardotService;
	} catch (e) {
		return {};
	}
};

Vue.prototype.$global = new Vue({
	data: {
		theme: themes.default,
	},
	methods: {
	  leaveAnimation() {
	    if (/Trident\/|MSIE /.test(window.navigator.userAgent)) {
	      return;
      }
	    let delayIndex = 0;
	    [...document.querySelectorAll('.animated-stepper-item')].reverse().forEach((item, index) => {
        setTimeout(() => {
          item.classList.remove('visible');
        }, delayIndex * 200);
        if (isInViewport(item)) {
          delayIndex++;
        }
      });
    },
	  enterAnimation() {
	    if (/Trident\/|MSIE /.test(window.navigator.userAgent)) {
	      return;
      }
	    setTimeout(() => {
        [...document.querySelectorAll('.animated-stepper-item')].forEach((item, index) => {
          setTimeout(() => {
            item.classList.add('visible');
          }, index * 200);
        });
      }, 0);
    },
		applyTheme() {
			const themeData = store.state.cms.theme
				? store.state.cms.theme
				: themes.default;

			vue.$vuetify.theme = { ...themeData.colors };
		},
	},
});

Vue.prototype.$dialog = new Vue({
	methods: {
	  open(payload) {
	    EventBus.$emit('dialog-open', payload);
    },
    close() {
	    EventBus.$emit('dialog-close');
    }
	}
});
