import {ActionTree, ActionContext} from 'vuex';
import _ from 'lodash';
import {SET_PRODUCTS, RESET_PRODUCTS, RESET, SET_THEME, SET_LAYOUT, SET_OTHER_PRODUCTS, SET_MESSAGES, SET_BANNER} from './mutationTypes';
import {ICMS, IRoot, IRawProduct} from '@/interfaces';

import {CMSContentService} from '@/services/CMSService';
import Enums from '@/utils/Enums';

const actions: ActionTree<ICMS, IRoot> = {
  async loadProducts(
    {commit, rootState, getters, rootGetters}: ActionContext<ICMS, IRoot>,
    payload: any,
  ): Promise<any> {
    const app: any = rootState.app;
    const products = rootGetters['products/getAllProductsWithLatestVersion']();
    const cmsProductKey = Enums.CMS.PRODUCTS.METADATA.PROD_CODE;

    const productCodes = _.map(products, (p: IRawProduct) => {
      return `${cmsProductKey}:${p.code}`;
    });

    const product = await CMSContentService.getProducts(app.targetSegment, app.language, productCodes);

    commit(SET_PRODUCTS, product);
    return Promise.resolve(product);
  },

  async loadOtherProducts(
    {commit, rootState, getters}: ActionContext<ICMS, IRoot>
  ): Promise<void> {
    const app: any = rootState.app;

    const otherProducts = await CMSContentService.getOtherProducts(app.targetSegment, app.language);
    commit(SET_OTHER_PRODUCTS, otherProducts);
  },

  async loadMessages({commit, rootState}: ActionContext<ICMS, IRoot>): Promise<any> {
    const app: any = rootState.app;
    const msgs = await CMSContentService.getMessages(app.targetSegment, app.language);
    commit(SET_MESSAGES, msgs);
    return Promise.resolve(msgs);
  },

  async loadTheme(
    {commit, rootState}: ActionContext<ICMS, IRoot>,
    payload: string,
  ): Promise<any> {
    const app = rootState.app;
    const theme = await CMSContentService.getThemes({ targetSegment: payload, language: app.language });
    if (theme) {
      commit(SET_THEME, theme);
    } else {
      console.error('missing theme config for segment: ' + payload);
    }
  },

  async loadCatalogueLayout({commit, rootState}: ActionContext<ICMS, IRoot>): Promise<any> {
    const app: any = rootState.app;
    const layout = await CMSContentService.getCatalogueLayouts(app.targetSegment);
    if (layout) {
      commit(SET_LAYOUT, layout);
      return Promise.resolve(layout);
    } else {
      console.error('missing layout config for segment' + rootState.app.targetSegment);
    }
  },

  async loadProductsProfiles({ rootState }: ActionContext<ICMS, IRoot>): Promise<any> {
    const app: any = rootState.app;
    return await CMSContentService.getProductsProfiles(app.targetSegment, app.language);
  },

  async loadProductsLines({ rootState }: ActionContext<ICMS, IRoot>): Promise<any> {
    const app: any = rootState.app;
    return await CMSContentService.getProductsLines(app.targetSegment, app.language);
  },

  reset({commit}: ActionContext<ICMS, IRoot>): void {
    commit(RESET);
  },

  resetProducts({commit}: ActionContext<ICMS, IRoot>): void {
    commit(RESET_PRODUCTS);
  }
};

export default actions;
