import CMSServiceImp from './CMSServiceImp';
import store from '@/store';
import _ from 'lodash';
import baseTranslationData from '@/settings/translation/base.json';
import baseBasicFormData from '@/settings/basicForm/base.json';
import baseOtherProductData from '@/settings/otherProducts/base.json';
import baseMessagesData from '@/settings/messages/base.json';
import baseLayoutData from '@/settings/layout/base.json';
import baseSurveyData from '@/settings/survey/base.json';
import baseProductProfileData from '@/settings/productProfile/base.json';
import baseProductLineData from '@/settings/productLine/base.json';
import baseStaticTextData from '@/settings/staticText/base.json';
import baseComponentImportantData from '@/settings/componentImportant/base.json';
import merge from 'deepmerge';

const formatTargetSegment = (segment: string): string => segment.toUpperCase();
export default class CMSContentService extends CMSServiceImp {

  public static async getTranslations(targetSegment, locale: string) {
    const translationOnTargetSegment = await import(`@/settings/translation/${formatTargetSegment(targetSegment)}/${locale}.json`).catch((error: any) => {
      console.info('no translation for target segment');
    });
    return translationOnTargetSegment ? [baseTranslationData, translationOnTargetSegment.default] : [baseTranslationData];
  }

  public static async getProducts(targetSegment: string, locale: string, productCodes: string[]) {
    const baseProduct = await import(`@/settings/products/${formatTargetSegment(targetSegment)}/index.json`);

    const localisedProduct = await import(`@/settings/products/${formatTargetSegment(targetSegment)}/${locale}.json`).catch((error: any) => {
      console.info('no localised product');
    });

    const productData = localisedProduct ? merge(baseProduct.default, localisedProduct.default) : baseProduct.default ;

    const products: any[] = [];

    for (const [code, fields] of Object.entries(productData)) {
      products.push(Object.assign({ code }, { fields }));
    }
    if (productCodes?.length > 0) {
      const filteredProducts =  products.filter((p: any) => {
        return p.fields.metadata?.filter((m: any) => productCodes.includes(m));
      });
      return filteredProducts;
    } else {
      return products;
    }
  }

  public static async getOtherProducts(targetSegment: string, locale: string) {
    const otherProductDataOnTargetSegment = await import(`@/settings/otherProducts/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no other product for targetSegment, using base other product instead');
    });
    const otherProductData = otherProductDataOnTargetSegment ? otherProductDataOnTargetSegment.default : baseOtherProductData;
    return otherProductData.filter((s: any) => s.locale === locale);
  }

  public static async getMessages(targetSegment: string, locale: string) {
    const messagesOnTargetSegment = await import(`@/settings/messages/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no message for targetSegment, using base messages instead');
    });


    const messagesData = messagesOnTargetSegment ? merge(messagesOnTargetSegment.default, baseMessagesData) : baseMessagesData;

    return messagesData.map((msg: any) => {
      return {
        code: msg.code,
        description: msg.description[locale],
      };
    });
  }

  public static async getStaticText(code: string, locale: string) {
    const targetSegment = _.get(store, 'state.app.targetSegment');

    const staticTextOnTargetSegment = await import(`@/settings/staticText/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no staticText for targetSegment, using base staticText instead');
    });

    const staticTextData = staticTextOnTargetSegment ? staticTextOnTargetSegment.default : baseStaticTextData;
    const text = staticTextData.find((st: any) => st.id === code);
    return Promise.resolve({
      fields: {
        code: text?.fields.code[locale],
        content: text?.fields.content[locale]
      }
    });
  }

  public static async getThemes(payload: {targetSegment, language}) {
    const targetSegment = formatTargetSegment(payload.targetSegment);

    const base = await import(`@/settings/theme/base.json`);
    if (payload?.language && targetSegment) {
      const segment = await import(`@/settings/theme/${targetSegment}/index.json`);
      const themeConfig: any =  merge(base.default, segment.default);

      if (themeConfig && themeConfig.targetSegment === targetSegment) {
        if (payload?.language) {
          const themeConfigLang: any = await import(`@/settings/theme/${targetSegment}/${payload.language}.json`).catch((error) => {
            console.error('loading theme config error', error);
          });
          return merge(themeConfig, themeConfigLang.default);
        }
        return themeConfig;
      } else {
        console.error('Missing theme config', targetSegment);
      }
    } else {
      return base;
    }
  }

  public static async getCatalogueLayouts(targetSegment: string) {
    const layoutOnTargetSegment = await import(`@/settings/layout/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no layout for targetSegment, using base layout instead');
    });

    return  layoutOnTargetSegment ? layoutOnTargetSegment.default : baseLayoutData;
  }

  public static async getBasicInfo(targetSegment: string, locale: string) {
    const basicFormOnTargetSegment = await import(`@/settings/basicForm/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no basic form for targetSegment, using base basic form instead');
    });

    const basicFormData = basicFormOnTargetSegment ? basicFormOnTargetSegment.default : baseBasicFormData;
    const data = basicFormData.map((field: any) => {
      return {
        ...field,
        label: field?.label?.[locale] || '',
        content: field?.content?.[locale] || ''
      };
    });
    return new Promise(async (resolve, reject) => {
      resolve(data);
    });
  }

  public static async getComponentImportantInformation(targetSegment: string, locale: string) {
    const cIIOnTargetSegment = await import(`@/settings/componentImportant/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no component important for targetSegment, using base component important instead');
    });
    const componentImportantData = cIIOnTargetSegment ? cIIOnTargetSegment.default : baseComponentImportantData;
    return componentImportantData.filter((d: any) => d.locale === locale);
  }

  public static async getSurvey(targetSegment: string, locale: string) {
    const surveyOnTargetSegment = await import(`@/settings/survey/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('mo survey for targetSegment, using base survey instead');
    });
    const surveyData = surveyOnTargetSegment ? surveyOnTargetSegment.default : baseSurveyData;
    return surveyData.filter((s: any) => s.locale === locale);
  }

  public static async getProductsProfiles(targetSegment: string, locale: string) {
    const productProfilesOnTargetSegment = await import(`@/settings/productProfile/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no product profile for targetSegment, using base product profile instead');
    });
    const productProfiles = productProfilesOnTargetSegment ? productProfilesOnTargetSegment.default : baseProductProfileData;
    return productProfiles?.map((profile) => {
      return {
        group: profile.group[locale],
        products: profile.products
      };
    }) || [];
  }

  public static async getProductsLines(targetSegment: string, locale: string) {
    const productLineOnTargetSegment = await import(`@/settings/productLine/${formatTargetSegment(targetSegment)}.json`).catch((error: any) => {
      console.info('no product line for targetSegment, using base product prolinefile instead');
    });
    const productLine = productLineOnTargetSegment ? productLineOnTargetSegment.default : baseProductLineData;
    return productLine?.map((line) => {
      return {
        group: line.group[locale],
        products: line.products
      };
    }) || [];
  }
}
