export class PardotService {
  public static reportBasicInfo(pardotId: any) {
    return Promise.resolve('ok');
  }

  public static reportConfiguration() {
    return Promise.resolve('ok');
  }

  public static reportDetails(businessSectionFacts: any) {
    return Promise.resolve('ok');
  }

  public static reportVerification(businessSectionFacts: any) {
    return Promise.resolve('ok');
  }

  public static reportSubmission(declaration: boolean, businessSectionFacts: any) {
    return Promise.resolve('ok');
  }

  public static reportPaymentIssue() {
    return Promise.resolve('ok');
  }

  public static reportContactUS(params: any) {
    return Promise.resolve('ok');
  }

  public static reportOfflineProducts(OfflineProducts: any) {
    return Promise.resolve('ok');
  }

  public static reportRenewal(params: any) {
    return Promise.resolve('ok');
  }

  public static reportRenewalAccessed() {
    return Promise.resolve('ok');
  }

  public static reportRenewalUsed() {
    return Promise.resolve('ok');
  }

  public static reportRenewalPaid(params: any) {
    return Promise.resolve('ok');
  }

  public static reportContinuationAccessed(params: any) {
    return Promise.resolve('ok');
  }

  public static reportContinuationPaid(params: any) {
    return Promise.resolve('ok');
  }
}
